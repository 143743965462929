export default {
	"Already a member?": "Ben je al lid?",
	"Login": "Verbinden",
	"Log out": "Uitloggen",
	"Your current status": "Huidige status",
	"BASIC": "BASIS",
	"Basic": "Basis",
	"Bronze": "BASIS",
	"Elite": "Elite",
	"VIP": "VIP",
	// "Join Us": "Entra ahora",
	// "Find people near you": "Encuentra gente cerca de ti",
	"Join now": "Bekijk profielen",
	"I am a": "Ik ben een",
	"Email": "E-mail",
	"It will not be published": "Vul je e-mailadres in",
	"E-mail address": "Vul je e-mailadres in",
	"SEARCH NOW": "Meld je gratis aan",
	"I confirm that over 18 years old": "Ik bevestig dat ik ouder ben dan 18 jaar",
	// "Do not just think about it! Discover the hottest dating site in Australia. Every day thousands of people like you join us.": "No lo pienses más y entra al fenómeno social del momento. Cada día miles de personas como tú se unen a nosotros.",
	"Information": "Informatie",
	"All rights reserved.": "Alle rechten voorbehouden.",
	"SUBSCRIPTION": "STATUS",
	"Other": "Anders",
	"All fields are required": "Alle velden zijn verplicht",
	"The fields marked in Red are required. Please fill them to continue.": "De velden gemarkeerd in rood zijn verplicht. Vul deze in om door te gaan.",
	"If you did not find a suitable answer please contact us": "Als je het juiste onderwerp niet hebt gevonden, neem dan contact met ons op",
	"Will help us to identify you": "Helpt ons om je te identificeren",
	"Invalid Username": "Ongeldige Gebruikersnaam",
	"Subject": "Onderwerp",
	"Tell us how can we help you": "Vertel ons hoe we je kunnen helpen",
	"Please enter subject": "Selecteer een onderwerp",
	"Please enter at least 10 characters": "Vul minimaal 10 tekens in",
	"Connected to your account": "Verbonden met je account",
	"Repeat Email": "Herhaal E-mail",
	"Emails did not match": "E-mail komt niet overeen",
	"Message": "Bericht",
	"No Recent Activity": "Geen recente activiteiten",

	//route labels
	"Home": "Home",
	"Contact": "Contact",
	"Support": "Ondersteuning",
	"Affiliates": "Affiliate",
	"Privacy": "Privacy",
	"Terms": "Voorwaarden",

	//routes uri
	"/login": "/inloggen",
	"/contact": "/contact",
	"/support": "/contact",
	"/affiliates": "/affiliate-programma",
	"/privacy": "/privacy",
	"/terms-and-conditions": "/algemene-Gebruiksvoorwaarden",
	"/cookie-policy": "/cookiebeleid",
	"/subscription/exit": "/betaling/uitgang",
	"/purchase-confirmation": "/aankoopbevestiging",
	"/page/:page_name": "/pagina/:page_name",

	"/dashboard": "/dashboard",
	"/mobile-dashboard": "/mobiel-dashboard",
	"/activate-profile/:token": "/activeren/:token",
	"/edit-profile": "/bewerk-profiel",
	"/profile/:name": "/profiel/:name",
	"/my-picture": "/mijn-afbeeldingen",
	"/messages/inbox": "/berichten/inbox",
	"/messages/trash": "/berichten/prullenbak",
	"/messages/send/:name": "/berichten/verzenden/:name",
	"/visitor": "/bezoekers",
	"/favorite/my": "/favorieten/mijn",
	"/favorite/others": "/favorieten/anderen",
	"/search": "/zoeken",
	"/search-result": "/zoek-resultaten",
	"/settings": "/instellingen",
	"/validate-new-email-address/:token": "/controleer-nieuw-e-mailadres/:token",
	"/subscription": "/betaling",
	"/credits": "/credits",
	"/elite": "/elite",
	"/faq": "/veelgestelde-vragen",
	"/delete-account": "/gebruiker-verwijderen",

	"/exclusive-promotion": "/exclusieve-promotie",
	"/account-deleted": "/account-verwijderd",
	"/logged-out": "/succesvol-uitgelogd",

	//login
	"Login": "Inloggen",
	"Enter username or Email": "Vul gebruikersnaam of e-mailadres in",
	"Enter Password": "Wachtwoord Invullen",
	"Username": "Gebruikersnaam",
	"Nickname": "Gebruikersnaam",
	"Password": "Wachtwoord",
	"I forgot my password": "Ik ben mijn wachtwoord vergeten",
	"Password Reminder": "Wachtwoord vergeten",
	"Enter you email": "Vul je e-mailadres in",
	"Your email address": "Je e-mailadres",
	"Don't have an account?": "Nog geen account?",
	"REGISTER": "REGISTREREN",
	"Password sent to your email": "Wachtwoord verzonden naar je e-mail",
	"This account has not been validated yet": "Dit account is nog niet geverifieerd",
	"LOGIN USING YOUR CREDENTIALS": "INLOGGEN MET JE GEGEVENS",
	"FORGOT YOUR PASSWORD?": "WACHTWOORD VERGETEN?",
	"Login failed": "aanmelden mislukt",
	"Username or password is incorrect": "Gebruikersnaam of wachtwoord is onjuist",

	"We're sorry!": "Het spijt ons",
	//registration
	"You're almost done!": "Je bent bijna klaar!",
	"Please fill your additional account information": "Vul je aanvullende accountinformatie in",
	"Just one step left!": "Nog maar één stap verwijderd!",
	"Let us learn more about you. Fill in your personal data": "We willen je beter leren kennen. Vul je persoonlijke gegevens in",
	"Activate your account now": "Activeer nu je account!",
	"Activate your account now.": "Activeer nu je account!",
	"Please confirm your e-mail address": "Bevestig je e-mailadres",
	"Please fill in the following form to join {site_name} for free": "Vul het onderstaande formulier in om gratis lid te worden van {site_name}",
	"CONTINUE": "DOORGAAN",

	"By clicking on the continue button I agree with the": "Door op doorgaan te klikken ga je akkoord met de",
	"terms": "Voorwaarden",
	"and cookies": "en het cookiebeleid",

	"This site is protected by reCaptcha and the Google": "Deze site wordt beschermd door reCaptcha en de Google",
	"Privacy Policy": "Privacybeleid",
	"and": "en",
	"Terms of Service": "Servicevoorwaarden",
	"apply.": "zijn van toepassing.",

	"Personal description": "Persoonlijke omschrijving",
	"optional": "optioneel",
	"FINALIZE": "AFRONDEN",
	"We have just sent you a confirmation e-mail at": "We hebben je zojuist een e-mail ter bevestiging gestuurd naar",
	"To start using our website, please click on the activation link in this email.": "Om onze website te gebruiken, klik je op de activeringslink in deze e-mail.",
	"Click here to verify your email": "Klik hier om je e-mail te bevestigen",
	"CLICK TO ACTIVATE": "ACTIVEREN KLIK HIER",
	"CLICK TO ACTIVATE - desktop": "ACTIVEREN KLIK HIER",
	"VERIFY MY EMAIL": "VERIFIEER MIJN E-MAIL",
	"Not received any email yet?": "Heb je nog geen e-mail ontvangen?",
	"Please check your": "Controleer je",
	"spam": "spam",
	"box and wait at least 10 minutes or re-send activation": "MAP en wacht minimaal 10 minuten of verzend de activering opnieuw.",
	"Enter Nickname": "Vul een Gebruikersnaam in",
	"Enter Your Name": "Vul je naam in",
	"Password Required": "Wachtwoord verplicht",
	"Enter valid Email": "Vul een geldig e-mailadres in",
	"Please enter at least 5 characters": "Vul minimaal 5 karakters in",
	"Enter strong password": "Vul een sterk wachtwoord in",
	"Email not available": "E-mailadres is niet beschikbaar",
	"Nickname not available": "Gebruikersnaam is niet beschikbaar",
	"Maximum 4 number allowed": "Maximaal 4 cijfers toegestaan",
	"Name should not contain any space, number or special character": "Naam mag geen spatie, nummers of speciale karakters bevatten",
	"Nickname should not contain any space or special characters": "Gebruikersnaam mag geen spatie of speciale tekens bevatten",
	"Your activation mail has been sent": "Je activeringsmail is verzonden",
	"Complete your profile in a minute and start contacting people": "Maak je profiel compleet en neem contact op met mensen",
	"Complete your profile": "Voltooi uw profiel",
	"Create Profile": "Wordt nu lid",
	"Your description": "Je omschrijving",
	"Confirm your Email": "Bevestig je e-mail",
	"We have sent you an email.": "We hebben je een e-mail gestuurd.",
	"Check your email to complete your registration": "Controleer je e-mail om je registratie te voltooien",
	"and be able to speak with other users.": "en met andere leden te kunnen praten.",
	"If you can not find it look it up in the spam or": "Als je het niet kunt vinden, zoek je het op in de map SPAM of",
	"If you have not received it select one of the following options": "Als je het niet hebt ontvangen, selecteer je een van de volgende opties",
	"junk folder": "ongewenste e-mail",
	"Change Email": "E-mail Wijzigen",
	"Resend Email": "E-mail opnieuw verzenden",
	"Resend": "Herzenden",
	"New Email": "Nieuwe E-mail",
	"Your activation key is invalid": "Je activeringscode is ongeldig",
	"Please include an '@' in the email address. '{email}' doesn't have an '@'": "Voeg een '@' toe aan het e-mailadres. '{email}' heeft geen '@'.",

	//public pages
	"privacy": "privacy",
	"terms-and-conditions": "algemene-Gebruiksvoorwaarden",
	"about-us": "over-ons",

	//forms
	"Name": "Naam",
	"Marital Status": "Burgerlijke Staat",
	"Date of Birth": "Geboortedatum",
	"Country": "Land",
	"Location": "Provincie",
	"Province": "Provincie",
	"City": "Stad",
	"Save Changes": "Wijzigingen Opslaan",
	"Select from list": "Selecteer uit de lijst",
	"Day": "Dag",
	"Month": "Maand",
	"month": "Maand",
	"Year": "Jaar",
	"Prefer not to say": "Vertel ik liever niet",
	"Only profiles with photo": "Alleen profielen met foto",
	"This field is required": "Dit veld is verplicht",
	"Please update your profile": "Werk je profiel bij",
	"Profile successfully updated": "Profiel met succes bijgewerkt",
	"The fields marked in red are required. Please fill them to continue.": "De velden gemarkeerd in rood zijn verplicht. Vul deze in om door te gaan.",
	"Characters left": "tekens over",
	"Image attached": "Afbeelding bijgevoegd",
	"The message field is required.": "Het berichtenveld is verplicht.",
	"WITH PICTURE": "MET FOTO",
	"all age groups": "Leeftijdscategorie",
	"EDIT DESCRIPTION": "WIJZIG OMSCHRIJVING",
	"DESCRIPTION": "OMSCHRIJVING",
	"FEATURING": "KARAKTER",
	"Please Select": "Selecteer",
	"Emails do not match": "E-mail komt niet overeen",
	"Your request was succesfully sent!": "Je aanvraag is succesvol verzonden!",
	"Or send us a message directly": "Of stuur ons een e-mail",

	//user pages
	"My Profile": "Mijn Profiel",
	"Add Photos": "Foto's toevoegen",
	"Messages": "Berichten",
	"Favorites": "Favorieten",
	"Search": "Zoeken",
	"Settings": "Instellingen",
	"Visits": "Bezoekers",
	"Likes": "Likes",
	"Membership": "Lidmaatschap",
	"Vip Members": "VIP Leden",

	"Account activation successful": "Account activering geslaagd",
	"My chats": "Mijn gesprekken",

	//user home
	"Quick Search": "Snel Zoeken",
	"More Options": "Meer Opties",
	"I am a": "Ik ben een",
	"Looking for": "Op zoek naar",
	"Age": "Leeftijd",
	"Search for": "Nu zoeken",
	"All age groups": "Alle leeftijdsgroepen",
	"Popular Members": "Populaire Leden",
	"New Members": "Nieuwe Leden",
	"Find a Match": "Vind een Match",
	"Recent Activity": "Recente activiteit",
	"Add Photo": "Foto toevoegen",
	"Back": "Terug",
	"Become Elite": "Upgrade",
	"Upgrade": "Upgrade",

	"likes": "LIKES",
	"EDIT MY PROFILE": "MIJN PROFIEL WIJZIGEN",
	"CONFIGURATION": "CONFIGURATIE",
	"ONLINE SUPPORT": "ONLINE ONDERSTEUNING",

	//user message
	"Trash": "Prullenbak",
	"Delete Selected": "Verwijder berichten",
	"No Messages Available": "Geen Berichten beschikbaar",
	"No Messages selected": "Geen Berichten geselecteerd",
	"Back to inbox": "Terug naar inbox",
	"Back to messages": "Terug naar berichten",
	"Select all": "Selecteer alles",
	"Deselect all": "Deselecteer alles",
	"Next Page": "Volgende Pagina",
	"Prev Page": "Vorige Pagina",
	"Type your message here": "Schrijf je bericht hier",
	"Send message": "Bericht verzenden",
	"Send Icebreaker": "Verstuur IJsbreker",
	"VIEW PROFILE": "BEKIJK PROFIEL",
	"DELETE": "VERWIJDEREN",
	"Chat with": "Chatten met",
	"Chat": "Chat",

	//user favorites
	"My Favorites": "Mijn Favorieten",
	"No likes": "Geen leden",
	"Favorite added successfully": "Favoriet met succes toegevoegd",
	"Favorite deleted successfully": "Je favoriet is succesvol verwijderd",
	"Visit Profile": "Bezoek Profiel",

	//user visits
	"My Profile Visitors": "Mijn Profiel Bezoekers",
	"No Visitors": "Geen Bezoekers",

	//user photos
	"My pictures": "Mijn foto's",
	"See my public profile": "Je openbare profiel",
	"Profile photo": "Profiel foto",
	"Profile Picture": "Profiel foto",
	"2MB or lower.": "2MB of lager.",
	"2MB or lower": "2MB of lager",
	"Nudes not allowed in this category.": "Naaktfoto's zijn niet toegestaan in deze categorie.",
	"PHOTO ALBUM": "FOTOALBUM",
	"Allowed images 2 megabytes or less": "toegestane afbeeldingen van 2 megabytes of minder",
	"Add picture": "Foto toevoegen",
	"Main reasons for rejection": "BELANGRIJKSTE REDENEN VOOR AFWIJZING",
	"Are you sure?": "Weet je het zeker?",
	"Are you sure you want to delete this image?": "Weet je zeker dat je deze afbeelding wilt verwijderen?",
	"Close": "Direct beginnen",
	"Confirm": "Bevestigen",
	"Attaching image": "Afbeelding toevoegen",
	"The message must be at least 2 characters.": "Het bericht moet uit minimaal 2 karakters bestaan.",
	"Deleting...": "Verwijderen...",
	"Pictures of underage people": "Foto's van minderjarigen",
	"Personal data is visible": "Persoonlijke gegevens zijn zichtbaar",
	"Fake or stolen pictures": "Valse of gestolen foto's",
	"Different gender to profile": "Verkeerd invullen van het geslacht",
	"Group pictures": "Groepsfoto's",
	"Weapons, drugs, violence": "Wapens,drugs, geweld",
	"No people visible": "Geen mensen zichtbaar",
	"Info": "Info",
	"We are reviewing your images. When they pass the verification process they will be shown to the other users.": "We zijn je foto's aan het beoordelen. Wanneer ze het verificatieproces zijn gepasseerd, worden ze aan de andere leden getoond.",

	//user profiles
	"Profile": "Profiel",
	"yrs": "jaar",
	"years": "jaar",
	"yr from": "jaar uit",
	"Physique": "Lichaamsbouw",
	"Photos": "Foto's",
	"No Photos": "Geen Foto's",
	"About Me": "Over Mij",
	"Characteristics": "Eigenschappen",
	"Prefers": "Geeft de voorkeur aan",
	"Relationship": "Relatie",
	"Shape": "Vorm",

	//user edit profile
	"DESCRIBE YOURSELF": "OMSCHRIJF JEZELF",
	"GENERAL": "ALGEMEEN",
	"(Will be verified)": "(wordt geverifieerd)",
	"Sex": "Ik ben een",
	"Seeking": "Op zoek naar",
	"Finish your profile": "Vul je profiel in",
	"Welcome! As your profile is new, we will give maximum visibility once you complete it.": "Welkom! Omdat je profiel nieuw is, geven we je maximale zichtbaarheid. Vul je profiel volledig in om hier gebruik van te maken. ",

	//search profile
	"Advanced Search": "UITGEBREID ZOEKEN",
	"OPEN TO": "STA OPEN VOOR",
	"PREFERENCES": "VOORKEUREN",
	"CHARACTER": "KARAKTER",
	"Search Result": "Zoekresultaat",
	"No Member found": "Geen Leden gevonden",
	"Change Search": "Wijzig zoekopdracht",
	"Hair Color": "Haarkleur",
	"Eye Color": "Kleur ogen",
	"Body Type": "Lichaamsbouw",
	"Height": "Lengte",
	"Weight": "Gewicht",
	"Smoker": "Roker",
	"Ethnicity": "Etniciteit",
	"From": "Van",
	"To": "Naar",

	//settings
	"Change E-mail address": "E-mailadres wijzigen",
	"Your email address has been successfully updated": "Je e-mailadres is succesvol bijgewerkt",
	"Password": "Wachtwoord",
	"Account Status": "Account Status",
	"Cancel": "Annuleer",
	"Delete Account": "Verwijder Account",
	"Notifications": "Meldingen",
	"Receive all notifications individually": "Ontvang alle meldingen afzonderlijk",
	"Receive one daily email will all my notifications": "Ontvang één dagelijkse e-mail met gegroepeerde meldingen",
	"Receive a weekly email with all my notifications": "Ontvang een wekelijkse e-mail met gegroepeerde meldingen",
	"I don\'t want to receive any notifications": "Ik wil geen meldingen ontvangen",
	"(we will send you a verification email)": "(je krijgt een verificatie e-mail)",
	"Current Password": "Huidig Wachtwoord",
	"New Password": "Nieuw Wachtwoord",
	"Enter valid email": "Vul een geldig e-mailadres in",
	"Repeat New Password": "Herhaal Nieuw Wachtwoord",
	"Save Settings": "Instellingen opslaan",
	"Passwords should match": "Wachtwoord moet overeenkomen",
	"Password length should be minimum 5 characters": "Wachtwoordlengte moet minimaal uit 5 karakters bestaan",
	"Attention: Your new email address must be verified by clicking on the link that we just sent you.": "Let op: Je nieuwe e-mailadres moet worden geverifieerd door op de link te klikken die we je zojuist hebben gestuurd.",
	"I want to receive notifications by email": "Ik wil meldingen per e-mail ontvangen",
	"Status": "Voorwaarden",
	"Renewal": "Vernieuwen",
	"Auto renewal Canceled": "Annuleer automatische vernieuwing",
	"Delete profile": "Verwijder profiel",
	"Your account has been deleted. You will be redirected.": "Je account is verwijderd. Je wordt doorverwezen.",
	"Successfully updated": "Succesvol geüpdatet",
	"Current account status": "Huidige account status",
	"Are you sure you want to cancel your subscription?": "Weet u zeker dat u uw abonnement wilt annuleren?",
	"Your subscription has been successfully canceled and will not be auto-renewed": "Uw abonnement is succesvol geannuleerd en wordt niet automatisch verlengd",
	"Due to technical reasons, cancellation of the renewal of the trial must be made after 8 hours of the acquisition. For any other subscription, the cancellation must be done at least 8 hours after acquisition and before 48 hours of its expiration.": "Om technische redenen moet de annulering van de verlenging van de proefperiode na 8 uur van de aankoop plaatsvinden. Voor elk ander type betaling moet de annulering worden gedaan na 8 uur van de aankoop en vóór 48 uur van de vervaldatum.",
	"Profile deleted. Redirecting...": "Profiel verwijderd. Wordt omgeleid...",
	"*Active subscriptions deleted this way will not be reimbursed": "*Actieve abonnementen die op deze manier worden verwijderd, worden niet terugbetaald.",
	"Are you sure you want to delete * your account? Your information will be permanently erased.": "Weet je zeker dat je wilt verwijderen * je account? Je informatie zal permanent worden gewist.",
	"Settings successfully updated": "Instellingen succesvol bijgewerkt",
	"Disable Notification": "Meldingen uitschakelen",
	"The notifications have been successfully disabled.": "De notificaties zijn succesvol gedeactiveerd.",
	"Canceled Auto-Renewal": "Automatische Verlenging Geannuleerd",

	//subscription
	"Only Vip members can contact more than 5 different users per day.": "Alleen Vip-leden kunnen per dag contact opnemen met meer dan 5 verschillende leden.",
	"Upgrade your status": "Stap 1: Kies je lidmaatschap",
	"Enjoy our exclusive benefits and make new contacts": "Geniet van onze exclusieve voordelen en neem contact op voor minder dan € 0,03 per dag",
	"Users Online": "Online Leden",
	"Discreet Billing. {site_name} will not appear in the invoice": "Facturering is Discreet. {site_name} zal niet op de factuur verschijnen",
	"Month ::: Months": "Maand ::: Maanden",
	"3 days trial": "3 dagen Proefperiode",
	"Try 3 days": "Probeer 3 dagen",
	"MEMBERSHIP": "ABONNEMENT",
	"Select": "Koop",
	"Send messages for 3 consecutive days": "Verstuur berichten gedurende 3 dagen",
	"Only one trial subscription per user": "Slechts één proefabonnement per gebruiker",
	"In order to offer better service without interruptions, all plans will be auto renewed at the cost of one of the following subscriptions based on the pack chosen above.": "Het abonnement wordt automatisch verlengd op basis van de prijs van het hierboven geselecteerde pakket",
	"for": "voor",
	"For full access to all details of events, including how to cancel it,": "Voor volledige toegang tot alle details, inclusief hoe je deze kunt annuleren,",
	"click here": "klik hier",
	"for terms and conditions,": "PourVoor Algemene voorwaarden,",
	"All profiles are reviewed and moderated by our team.": "Alle profielen worden beoordeeld en gemodereerd door ons team.",
	"CURRENT PLAN": "HUIDIG ABONNEMENT",
	"Basic (no trial bonus) You do not have any active pack at this moment and you are a BASIC user. To increase your chances of connecting with and contacting other users, you will need to upgrade to Elite or VIP status.": "Basis (geen proefperiode) momenteel heb je geen actief pakket en je status is BASIS. Om je kansen op contact met andere leden aanzienlijk te vergroten, heb je de ELITE-status nodig.",
	"MY BENEFITS": "MIJN VOORDELEN",
	"{discount} cheaper": "{discount} goedkoper",
	"save": "UIT",
	"Create my profile": "Aanmaken van mijn profiel",
	"Read messages": "Berichten lezen",
	"Add to Favorites": "Toevoegen aan Favorieten",
	"Make yourself visible to other users by adding them to favorites": "Maak jezelf zichtbaar voor andere leden door ze toe te voegen aan je favorieten",
	"Photo Album": "Fotoalbum",
	"No fake pictures allowed": "Geen nepfoto's toegestaan",
	"Album pictures": "Album afbeeldingen",
	"Show yourself and increase your possibilities": "Laat jezelf zien en vergroot je kansen",
	"Detailed profile search": "Uitgebreid zoeken naar profielen",
	"Find exactly what you are looking for": "Vind precies wat je zoekt",
	"See other user's pics": "Bekijk foto's van de andere leden",
	"Choose your favorite": "Kies degene die je het leukst vindt",
	"Reply to and send messages": "Verzend of beantwoord berichten",
	"Make direct contact with up to five users per day": "Maak direct contact met maximaal vijf verschillende leden per dag",
	"Be shown first in searches": "Wordt als eerste weergegeven bij zoekopdrachten",
	"Your profile will be featured before the rest of the users": "Je profiel wordt als eerste weergegeven voorafgaand de andere(basis)leden",
	"Make direct contact with unlimited users": "Neem direct onbeperkt contact op met wie je maar wilt",
	"Choose payment method": "Kies een betalingsmethode",
	"LESS THAN": "MINDER DAN",
	"PER DAY": "PER DAG",
	"DISCRETION": "DISCRETIE",
	"Our website name will not be disclosed": "Onze naam verschijnt niet op de factuur",
	"Our name will not be disclosed": "Onze naam verschijnt niet op de factuur",
	"100% Anonymous.": "100% Anoniem.",
	"Choose your subscription": "Kies uw abonnement",
	"Recommended": "Aanbevolen",
	"Most Popular": "Meest populair",
	"Secure payments with": "Veilige betalingen met",
	"Your message has NOT been sent. You need to upgrade your status in order to send messages.": "Je bericht is niet verzonden. Je moet je abonnement aanpassen om je berichten te kunnen versturen.",
	"Your purchase has been successful": "Je aankoop is succesvol verlopen",
	"All subscriptions automatically renew to ensure optimum service. If you want to change the settings you can do it": "Alle abonnementen worden automatisch verlengd om een optimale service te garanderen. Als u de instellingen wilt wijzigen, kunt u dit",
	"here.": "hier",
	"after_here": "doen.",

	//ad
	"Become a": "Wordt",
	"PREMIUM MEMBER": "PREMIUM LID",
	"And contact": "En neem contact op",
	"more people": "met meer leden",

	"upgrade": "Upgrade",
	"Boost": "Verbeter",
	"boost": "Verbeter",
	"your profile": "uw profiel",
	"Start chatting and meet more people": "Chat nu en ontmoet meer leden",
	"And get": "En krijg",
	"more views": "meer bezoekers",

	"Now +3 extra Credits with each purchase": "Momenteel 3 extra Credits bij elke aankoop",
	"Best Seller": "Bestseller",
	"Select your Package": "Selecteer uw pakket",
	"Less than {amount} per credit": "Minder dan {amount} per credit",
	"Less than": "Minder dan",
	"per credit": "per credit",
	"Free credits": "Gratis credits",
	"per purchase": "bij elke aankoop",
	"Premium Status doesn’t allow free messages.": "Als Premium Lid kunt u geen gratis berichten versturen.",
	"Chat now": "Chat nu",
	"Get Credits": "Verkrijg Credits",
	"Credit": "Credit",
	"Credits": "Credits",
	"My credits": "Mijn credits",
	"{count} Credits left": "{count} Credits beschikbaar",
	"Your message has not been sent, you need credits to be able to send messages.": "Uw bericht is NIET verzonden. U moet credits hebben om berichten te kunnen verzenden.",
	"Icebreaker": "IJsbreker",
	"Icebreaker today": "IJsbreker vandaag",
	"The cost for sending an Icebreaker is 2 credits. The Icebreaker can only be used to start a conversation or in response to another icebreaker.": "De kosten voor het versturen van een IJsbreker is 2 credits. De IJsbreker kan alleen worden gebruikt om een gesprek te starten of als reactie op een andere IJsbreker.",
	"With the acquisition of each package, users make a purchase of a pack of credits that can be used to send messages. The ratio of credits per message is ten to one, that means, the user needs to have at least ten credits in his account to send a message. The cost for sending an Icebreaker is 2 credits.": "Bij het kopen van een pakket verwerft de gebruiker een pakket tegoeden dat wordt gebruikt om berichten te verzenden. De verhouding van credits per bericht is tien tegen één, dat wil zeggen dat de gebruiker ten minste tien credits in zijn account moet hebben, die gebruikt zullen worden om een bericht te verzenden. De kosten voor het versturen van een IJsbreker is 2 credits.",

	"Trial pack": "Proefpakket",
	"Only one trial pack per user allowed": "Slechts één proefpakket per gebruiker toegestaan",
	"Used": "Gebruikt",

	"Exclusive Promotion": "Exclusieve promotief",
	"Account Deleted": " Account Verwijderd",
	"Log out Successful": "Succesvol Uitgelogd",

	"Do you like FREE LIVE SEX?": "Houd je van GRATIS LIVE SEX?",
	"Enter this code to enjoy a 10% discount:": "Geniet van een korting van 10% met behulp van de code:",
	"Discount redeemable for your first online purchase. Expires in 2 hours.": "Deze code is geldig voor de komende 6 uur bij de eerste aankoop op het web.",

	"This account has been deleted.": "Dit account is verwijderd.",
	"Our algorithm has found a better option in line with your taste.": "Ons algoritme heeft een betere optie gevonden op basis van je voorkeur.",
	"You will be redirected in a few seconds.": "Je wordt binnen enkele seconden doorverwezen.",

	"Has added you to favorites": "Heeft je toegevoegd aan favorieten",
	"Is looking at your profile": "Is jouw profiel aan het bekijken",
	"See profile": "Zie profiel",
	"See message": "Reageer",

	"I think I am in love": "Ik denk dat ik verliefd word",
	"Hey! I'm winking at you": "Hé! Deze knipoog is voor jou",
	"Tell me more about you. Fancy a chat?": "Jij bent interessant! Zullen we chatten?",
	"Some flowers for you!": "Bloemen voor jou!",
	"Sending you a kiss": "Ik stuur je een kus",
	"Surprise, it\'s me!": "Verrassing, ik ben het! ",
	"Hi, how are you?": "Hallo, hoe gaat het met je?",
	"Let's get naughty": "Laten we ondeugend worden",

	"Get 5 times the regular response rate. Premium members benefits:": "Profiteer en krijg 5 keer zoveel reacties. De voordelen van de premiumleden zijn:",
	"Appear high up on searches": "Je profiel verschijnt bovenaan in de zoekopdrachten",
	"Distinctive badge that increases your visibility": "Onderscheidingsteken om je zichtbaarheid te vergroten",
	"5 Free Icebreakers a day": "5 gratis IJsbrekers per dag",
	"In addition to this, a voucher for a 15% Webcam discount": "En bovendien 15% korting op Webcams",

	"The Icebreaker can only be used to start a conversation or in response to another icebreaker.": "De IJsbreker kan alleen worden gebruikt om een gesprek te starten of als reactie op een andere IJsbreker.",

	"Succesful purchase": "Succesvolle aankoop",
	"Your purchase has been successful.": "Uw aankoop is succesvol voltooid.",
	"It can take a few minutes to update.": "Het kan enkele minuten duren voordat het wordt weergegeven.",
	"You will be redirected.": "U wordt doorgestuurd.",

	"Changes can take some minutes to update.": "Het kan enkele minuten duren voordat de wijzigingen zijn doorgevoerd.",

	"Yearly Package": "Jaarlijks pakket",
	"Package": "Pakket",
	"Buy Now": "Koop nu",
	"The Elite status allows you to contact up to 5 different users every day by sending them unlimited messages.": "Met het Elite lidmaatschap kunt u elke dag contact opnemen met maximaal 5 verschillende gebruikers door hen onbeperkt berichten te sturen.",

	"Exclusive offer": "Exclusief aanbod",
	"Welcome": "Welkom",
	"Mary from {domain_name} support": "Anne van het {domain_name} support team",
	"Welcome to {domain_name}. We hope you find what you are looking for.": "Welkom bij {domain_name}. We hopen dat u vindt waarnaar u op zoek bent.",
	"Here are some Pro Tips to be more successful and meet people:": "Hier zijn enkele succesvolle tips, die ervoor zorgen dat u eerder mensen kunt ontmoeten:",
	"Upload a profile picture to get more connections.": "Upload een foto voor meer interactie.",
	"Send an icebreaker to get a conversation easily started.": "Stuur een IJsbreker om sneller een gesprek te beginnen.",
	"If you like someone don’t forget to add them to favorites so they will know.": "Als u iemand leuk vindt, vergeet dan niet om deze persoon aan uw favorieten toe te voegen, zodat deze daar ook van op de hoogte is.",
	"And of course, if you like somebody send a message and ask for a date!": "En natuurlijk, als u iemand leuk vindt, stuur dan een bericht en vraag om een date!",
	"If you need any extra help, please contact our ": "Neem contact op met onze ",
	"support": "support",
	". We will be happy to help you.": " als u extra hulp nodig heeft. Wij helpen u graag verder",
	"Try your luck": "Probeer je geluk",
	"64% of our users have a date in the first 2 weeks.": "64% van onze gebruikers heeft een date in de eerste 2 weken.",
	"Good Luck!.": "Succes!",
	"*This message will be deleted upon closing.": "*Dit bericht wordt verwijderd nadat u het sluit.",

	"Private picture": "Privéfoto",
	"Private pictures will appear blurred out. Only users that have already paid will be able to see them.": "Privéfoto's worden wazig weergegeven. Alleen gebruikers die eerder een betaling hebben gedaan, kunnen deze zien.",
	"Name change": "Naam wijzigen",
	"If you want to change your name or nickname, please contact our support team and we will do it within 24h.": "Als u uw naam of gebruikersnaam wilt wijzigen, neem dan contact op met onze ondersteuning en zij doen dit voor u binnen 24 uur.",
	"See all images": "Bekijk alle foto's",
	"Unlock all private photos forever for just 20 credits": "Voor slechts 20 credits ontgrendelt u alle privé foto's voor altijd",
	"Unlock forever all private pictures": "Ontgrendel privé foto's voor altijd",

	"This email address is not registered, please sign up to log in.": "Dit email adres is niet geregistreerd, registreer u alstublieft om in te loggen.",
	"Congratulations, your account is now active.": "Gefeliciteerd, uw account is actief.",
	"Complete one last step and have fun!": "Nog 1 stap te gaan om plezier te hebben!",
	"OR": "OF",

	"We sent you an email with a link to activate your account valid for the next 12 hours to:": "We sturen u een email met een link om uw account te activeren, deze link is 12 uur geldig vanaf:",
	"If you haven\'t received it in your inbox or ": "Als u het niet in uw inbox of ",
	"or you want to modify the email address, click on the button below.": "heeft ontvangen of u wilt het e-mailadres wijzigen, klik op de knop hieronder.",
	"We sent you an email to verify your account, the email will be valid for the next 12 hours:": "We hebben u een e-mail gestuurd om uw account te verifiëren. De e-mail is 12 uur geldig:",
	"If you can\'t find it in your inbox or spam, you can edit or resend it": "Als u het niet in uw mailbox of spam kunt vinden, kunt u het opnieuw verzenden of bewerken",
	"Resend or change email": "E-mail opnieuw verzenden of wijzigen",
	"Please use the same email you registered.": "Selecteer het e-mailadres dat gebruikt werd tijdens registratie.",
	"Connect with Google": "Verbind met Google",
	"Connect with Yahoo": "Meld je aan met Yahoo",
	"Connect with Microsoft": "Verbind met Hotmail",
	"Or turn back": "Ga terug",
	"Complete your registration": "Voltooi je registratie",

	"It will not be shared": "Wordt niet gedeeld",
	"It will never be shared. 100% Privacy": "Wordt nooit gedeeld. 100% privacy",

	"Unlock all private pictures forever.": "Ontgrendel privé foto's voor altijd.",
	"Unlock": "Ontgrendelen",

	"You will be redirected in a second": "U wordt binnen een seconde doorgestuurd",

	"Card payment": "Kaart betaling",
	"Bank transfer": "Bank Overschrijving",
	"Prepaid card": " Prepaid kaart",

	"Error": "Foutmelding",
	"page not found": "Pagina niet gevonden",
	"Back to homepage": "Terug naar homepagina",

	"per month": "per maand",
	"week": "week",
	"{count} week": "{count} week",

	"Your profile has been blocked": "Uw profiel is geblokkeerd",
	"In order to offer the best possible user experience, we block all profiles that haven\'t sent a message within 21 days of registration.": "Om de best mogelijke gebruikerservaring te bieden, blokkeren we alle profielen die geen bericht hebben verzonden binnen 21 dagen na registratie.",
	"Please acquire a credit package to unlock your profile and restore all its features.": "Koop een credit pakket om uw profiel te deblokkeren en alle functies te herstellen.",
	"Restore access to:": "Weer toegang tot:",
	"All messages": "Alle berichten",
	"Users search": "Gebruikers zoeken",
	"Your contacts": "Jouw contacten",
	"All favourites": "Alle Favorieten",
	"All pictures": "Alle foto's",
	"Send Icebreakers": "Stuur IJsbrekers",
	"Unblock Profile": "Profiel deblokkeren",

	"This website uses its own and third-party cookies and identifiers to improve accessibility and analyse our traffic. If you continue browsing, we consider that you accept its use. For more information visit our": "Deze website gebruikt eigen cookies en cookies van derden en identificatiemiddelen om de toegankelijkheid te verbeteren en om ons dataverkeer te analyseren. Als u doorgaat met surfen, gaan we ervan uit dat u het gebruik ervan accepteert. Voor meer informatie raadpleeg ons",
	"Cookies Policy": "cookiebeleid",

	"DATE GUARANTEE": "ONZE DATE GARANTIE",
	"Take advantage of our Date guarantee": "Profiteer van onze date garantie",
	"No date? Money Back!": "Geen date? Geld terug!",
	"No risk": "Zonder risico",
	"Guarantee of success": "Garantie voor succes",
	"Terms and Conditions": "Algemene Voorwaarden",

	"Boost your visibility": "Vergroot je zichtbaarheid",
	"SAVE": "BESPAAR",
	"Add 3 months premium": "Voeg 3 maanden premium toe",
	"Add {months} months premium": "Voeg {months} maanden premium toe",

	"We guarantee you a real date.": "Op onze website geven we je een echte date garantie.",
	"YES, I WANT A DATE": "JA, IK WIL EEN DATE",

	"Terms and conditions of the date guarantee. In order to fulfill all refund conditions, you must have a fully completed profile for a period of at least six months and your personal description in your profile. Your profile must contain at least one approved picture throughout this period. Furthermore, each week, you must contact at least seven different members located close to you (radius of 100km) within your age range (-5/+5 years). If you fulfill all the conditions listed above and you still were not able to find a date, we will full refund all the payments you made during this time. This guarantee is only valid for the first 12 months following your registration. Good luck!": "Algemene Voorwaarden van de date garantie. Om aan alle terugbetalingsvoorwaarden te voldoen, moet je een volledig profiel en een beschrijving in je profiel hebben voor een periode van minimaal zes maanden. Je profiel moet tijdens deze periode ten minste één goedgekeurde profielfoto bevatten. Bovendien moet je elke week communiceren met ten minste zeven verschillende leden die zich bij jou in de buurt (straal van 100 km) bevinden en binnen je leeftijdscategorie (-5 / +5 jaar). Voldoe je aan alle bovenstaande voorwaarden en je kunt geen date vinden, dan storten wij alle betalingen vanaf je inschrijving volledig terug. Deze garantie is slechts 12 maanden geldig na je registratie. Succes!",

	"You have {count} days premium left": "Je hebt nog {count} dagen Premium over",
	"Check our special offer before deleting your account!": "Bekijk onze speciale aanbieding voordat u uw account verwijdert!",
	"Delete my account": "Verwijder mijn account",
	"Free": "Gratis",

	"Premium, Elite and VIP packages are auto-renewable, which means they will auto-renew when they expire.": "De Premium Elite en VIP pakketten vernieuwen zichzelf, dat wil zeggen dat ze automatisch worden verlengd wanneer ze verlopen.",
	"and can confirm that I am 18 years or older.": "en bevestig ik, dat ik ouder ben dan 18 jaar.",

	"COMPLETE YOUR PURCHASE": "UW AANKOOP VOLTOOIEN",
	"Payment details": "Betalingsdetail",
	"Total": "Totaal",
	"3-digit number on the back of your credit card": "3-cijferig nummer op de achterkant van uw creditcard",
	"MM / YY": "MM / JJ",
	"MM": "MM",
	"YY": "JJ",
	"Name on card": "Naam op kaart",
	"Charges will appear discreetly as tpmpsup.com": "Betalingen worden discreet vermeld als tpmsup.com",
	"Buy Now": "Nu kopen",
	"Premium package 3 Months": "Premium pakket 3 maanden",

	"This purchase was declined. Please check your details and try again.": "Deze aankoop is geweigerd. Controleer uw gegevens en probeer het opnieuw.",
	"Your purchase was succesful.": "Uw aankoop is gelukt.",

	"SPECIAL OFFER": "SPECIALE AANBIEDING",
	"Unlock your account with our special offer": "Ontgrendel uw account met onze speciale aanbieding",
	"Take advantage of our special offer to unlock your profile and restore all its features.": "Profiteer van onze speciale aanbieding om uw profiel te ontgrendelen en alle functies te herstellen.",

	"card_number_require": "Kaartnummer is vereist",
	"card_number_invalid": "Kaartnummer is ongeldig",
	"card_name_require": "De naam van de kaarthouder is vereist",
	"card_name_invalid": "De naam van de kaarthouder is ongeldig",
	"card_cvc_require": "CVC is vereist",
	"card_cvc_invalid": "CVC is ongeldig",
	"card_expiry_require": "De vervaldatum van de kaart is vereist",
	"card_expiry_invalid": "De vervaldatum van de kaart is ongeldig",

	"Private photo": "Privé foto",
	"Search for someone now": "Search for someone now",
	"Edit": "Bewerk",

	"Locked Album": "Album vergrendeld",
	"Get credits to unlock all albums": "Koop credits om alle albums te ontgrendelen",
	"picture ::: pictures": "foto ::: foto's",
	"Open": "Openen",

	"Receive notifications": "Meldingen ontvangen",
	"I want to be notified when I receive a message": "Ik wil op de hoogte worden gehouden als ik een bericht ontvang",
	"No": "Nee",
	"Yes": "Ja",

	"logout_confirm_title": "Uitloggen", 
	"Are you sure you want to log out?": "Weet u zeker dat u wilt uitloggen?",

	"Your payment has been cancelled": "Je hebt je betaling geannuleerd",
	"Something went wrong, please try again in 10 minutes": "Er is iets misgegaan, probeer het over 10 minuten opnieuw",

	"Please accept the terms and conditions to continue": "Accepteer de algemene voorwaarden om door te gaan",
	"By checking this box and clicking continue I accept the": "Door dit vakje aan te vinken en op Doorgaan te klikken, accepteer ik",
	"rg_terms": "de Algemene voorwaarden",
	"rg_privacy": "het Privacybeleid",
	"rg_cookies": "de Cookies",
	"I am aware of the use of fictitious profiles on the website and I confirm that I am of legal age.": "en ben ik op de hoogte van het gebruik van fictieve profielen op de website en bevestig dat ik meerderjarig ben.",

	"Cookie": "Cookie",
	"Responsible Party": "Verantwoordelijke partij",
	"Category": "Categorie",
	"Cookie Name": "Name",
	"Purpose": "Doel",
	"Lifespan": "Levensduur",
	"Type of Cookie": "Soort cookie",
	"Strictly necessary": "Verplicht",
	"Functional": "Functioneel",
	"Performance": "Prestatie",
	"Advertising": "Advertising",
	"cookie-policy": "cookiebeleid",
	"cookie policy": "cookiebeleid",

	"We Care About Your Privacy": "Jouw privacy is belangrijk voor ons",
	"This site uses its own, technical and third party cookies to improve navigation performance, analyzing visitors behaviour to help us and our marketing partners measure campaign results and personalized advertising. By clicking \"Accept All\" you are agreeing to all of these cookies.": "Deze site maakt gebruik van eigen, technische en externe cookies om de navigatieprestaties te verbeteren, het bezoekersgedrag te analyseren en ons en onze marketingpartners te helpen de campagneresultaten te meten en gepersonaliseerde reclame. Door op \"Alles accepteren\" te klikken, ga je akkoord met al deze cookies.",
	"Accept All": "Alles accepteren",
	"Cookies Settings": "Cookiebeleid",
	"About your privacy": "Over je privacy",
	"We use cookies for traffic balancing, session identification, analytics and marketing attribution. You can opt-in or opt-out of any or all of them from this panel.": "We gebruiken cookies voor traffic balancing, sessie-identificatie, analyse en marketingattributie. Je kan je in dit paneel aan- of afmelden voor een of meer van deze.",
	"You will be able to change your set up at any time from our :cookies_policy page": "Je kan jouw instellingen op elk moment wijzigen vanaf onze pagina met :cookies_policy",
	"Accept all": "Accepteer alles",
	"Manage Consent Preferences": "Toestemmingsvoorkeuren beheren",
	"Technical (Mandatory)": "Technisch (verplicht)",
	"Performance (Monitor website performance)": "Prestaties (bewaakt de prestaties van de website)",
	"Functional (User experience improvement)": "Functioneel (verbetering van de gebruikerservaring)",
	"Advertising (Marketing measurement)": "Adverteren (marketingmeting)",
    "Advertising (Personalization)": "Adverteren (personalisatie)",
	"Save Selection": "Selectie opslaan",
	"Reject All": "Alles afwijzen",

	"We and our partners process data to provide:": "Wij en onze partners verwerken gegevens om:",
	"Use precise geolocation data. Actively scan device characteristics for identification. Store and/or access information on a device. Personalised ads and content, ad and content measurement, audience insights and product development.": "Gebruik nauwkeurige geolocatiegegevens. Scan actief apparaatkenmerken voor identificatie. Informatie op een apparaat opslaan en/of openen. Gepersonaliseerde advertenties en inhoud, advertentie- en inhoudsmeting, doelgroepinzichten en productontwikkeling.",
	"List of partners": "Lijst van partners",
	"Cookie Policy": "Cookiebeleid",
	"These cookies are used in connection with the tracking of actions related to advertisements. For example, these cookies will remember that you have visited {name} and can be used to show you a personalized advertisement on another website. Please check the following links of the platforms where we promote ourselves, Google, Microsoft and Twitter. for more information.": "Deze cookies worden gebruikt in verband met het volgen van acties met betrekking tot advertenties. Deze cookies onthouden bijvoorbeeld dat je {name} hebt bezocht en kunnen worden gebruikt om je een gepersonaliseerde advertentie op een andere website te tonen. Controleer de volgende links van de platforms waarop we onszelf promoten, Google, Microsoft en Twitter. voor meer informatie",
	"Delete cookies": "Cookies",
	"Save selection": "Selectie opslaan",
	"Reject all": "Alles afwijzen",

	"{name} hasn't replied to you yet. Send another message!": "{name} heeft nog niet op je gereageerd. Stuur nog een bericht!",
	"Validate my Ice-breaker": "Bevestig mijn ijsbreker",

	"Distance": "Afstand",
	"now": "nu",

	"Starting  June 1, 2023 onwards, we will not process payments with PaySafeCard.": "Vanaf 1 juni 2023 is betalen met een PaySafeCard niet meer mogelijk.",

	"Dear User,": "Beste klant,",
	"Thanks for your interest in joining our website, but, in order to keep a gender balance close to 50/50 men and women, registration is not allowed for men, at the moment.": "Bedankt voor uw interesse om lid te worden van onze website. De registratie voor mannen is momenteel echter gesloten om een ​​genderbalans van ongeveer 50% te behouden.",
	"If you still want a date, click on the button below and try one of the best websites online.": "Als u nog steeds iemand wilt ontmoeten, klik dan op de onderstaande knop en probeer een van de beste websites online.",
	"Try Now": "Probeer nu",
	
	"Your account has been deleted.": "Uw account is verwijderd.",
	"According to your choices, our algorithms have found a better option for you.": "Op basis van uw keuzes heeft ons algoritme een veel betere optie voor u gevonden.",
	"See More": "Bekijk meer",

	"10 credits for you!": "10 credits voor jou!",
	"Get 3 free Icebreakers": "Ontvang 3 gratis ijsbrekers",
	"Free Icebreakers": "Gratis ijsbrekers",
	"Congratulations, you have received 3 Icebreakers!": "Gefeliciteerd, je hebt 3 ijsbrekers gewonnen!",

	"Apple pay only works with the Safari browser. Please open your Safari browser and login to your account in order to proceed with this payment.": "Apple Pay werkt alleen in de Safari-browser. Open uw Safari-browser en log in op uw account om door te gaan met deze betaling.",

	"No nudity": "Geen naaktfoto’s",
	"Nudity allowed": "Naaktfoto’s toegestaan",

	"Edit profile": "Bewerk profiel",
	"Online": "Online",
	"Step": "Volgende",
	"Completed": "Voltooid",
	"Help": "Help",
	"or Drag and drop": "of slepen en neerzetten",

	"You will be able to send messages in": "Je kunt berichten sturen over",
	"Send": "Versturen",
	"Success": "Succes",
	"Start a conversation with {name}": "Begin een gesprek met {name}",
	"Failed": "Mislukt",
	"Congratulations, your account has been successfully created.": "Gefeliciteerd, uw account is succesvol aangemaakt.",
	"Contact our support": "Neem contact op met onze klantenservice.",
	
	"About": "Over",
	"Scale": "Aanpassen",
	"Rotate": "Draaien",

	"No favorites added": "Geen favorieten toegevoegd",

	"Didn\'t find what you were looking for?": "Niet gevonden wat u zocht?",
	"Please send us an email and we will get back to you.": "Stuur ons een e-mail en wij nemen zo snel mogelijk contact met u op.",
	"Or contact us using the form": "Of neem contact met ons op via het formulier."
	
	
}
