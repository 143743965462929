export default {
	"Already a member?": "Já és membro?",
	"Login": "ENTRAR",
	"Log out": "Sair",
	"Your current status": "O teu estado atual",
	"BASIC": "BÁSICO",
	"Basic": "Básico",
	"Bronze": "Básico",
	"Elite": "Elite",
	"VIP": "VIP",
	"Join Us": "Entra agora",
	"Find people near you": "Encontre pessoas perto de ti",
	"Join now": "Começar Agora",
	"I am a": "Eu sou",
	"Email": "E-mail",
	"It will not be published": "Não será publicado",
	"E-mail address": "Digite o e-mail",
	"SEARCH NOW": "PROCURA AGORA",
	"I confirm that I am over 18 years old": "Confirmo que tenho mais de 18 anos",
	"Do not just think about it! Discover the hottest dating site in Australia. Every day thousands of people like you join us.": "Não penses mais nisso e entra no fenómeno social do momento. Todos os dias milhares de pessoas como tu juntam-se a nós.",
	"Information": "Informação",
	"All rights reserved.": "Todos os direitos reservados.",
	"SUBSCRIPTION": "SUBSCRIÇÃO",
	"Other": "Outros",
	"All fields are required": "Todos os campos são obrigatórios",
	"The fields marked in Red are required. Please fill them to continue.": "Os campos marcados em vermelho são obrigatórios. Por favor, preencha-os para continuar.",
	"If you did not find a suitable answer please contact us": "Se não encontraste uma resposta adequada, por favor, entra em contacto connosco.",
	"Will help us to identify you": "Isso vai ajudar-nos a identificar-te",
	"Invalid Username": "Nome de utilizador inválido",
	"Subject": "Assunto",
	"Tell us how can we help you": "Diga-nos como podemos ajudá-lo",
	"Please enter subject": "Por favor, insira o assunto",
	"Please enter at least 10 characters": "Por favor, insira pelo menos 10 caracteres",
	"Connected to your account": "Conectado à sua conta",
	"Repeat Email": "Repete o e-mail",
	"Emails did not match": "Os e-mails não correspondem",
	"Message": "Mensagem",
	"No Recent Activity": "Nenhuma atividade recente",

	//route labels
	"Home": "Início",
	"Contact": "Contacto",
	"Support": "Apoio",
	"Affiliates": "Afiliados",
	"Privacy": "Privacidade",
	"Terms": "Termos",

	//routes uri
	"/login": "/iniciar-sessao",
	"/contact": "/contacto",
	"/support": "/apoio",
	"/affiliates": "/programa-de-afiliacao",
	"/privacy": "/privacidade",
	"/terms-and-conditions": "/termos-e-condições",
	"/cookie-policy": "/politica-de-cookies",
	"/subscription/exit": "/subscricao/sair",
	"/purchase-confirmation": "/confirmacao-compra",
	"/page/:page_name": "/pagina/:page_name",

	"/dashboard": "/inicio",
	"/mobile-dashboard": "/painel-de-controle-movel",
	"/activate-profile/:token": "/ativar-perfil/:token",
	"/edit-profile": "/meu-perfil",
	"/profile/:name": "/perfil/:name",
	"/my-picture": "/minhas-fotos",
	"/messages/inbox": "/mensagens/caixa-de-entrada",
	"/messages/trash": "/mensagens/lixeira",
	"/messages/send/:name": "/mensagens/enviar/:name",
	"/visitor": "/visitante",
	"/favorite/my": "/favoritos/meu",
	"/favorite/others": "/favoritos/outros",
	"/search": "/pesquisar-perfis",
	"/search-result": "/resultado-pesquisa",
	"/settings": "/definicoes",
	"/validate-new-email-address/:token": "/validar-novo-endereco-email/:token",
	"/subscription": "/subscricao",
	"/credits": "/creditos",
	"/elite": "/elite",
	"/faq": "/perguntas-e-respostas",
	"/delete-account": "/eliminar-conta",

	"/exclusive-promotion": "/promocao-exclusiva",
	"/account-deleted": "/conta-eliminada",
	"/logged-out": "/sessao-encerrada",

	//login
	"Login": "ENTRAR",
	"Enter username or Email": "Digite o nome de utilizador ou o e-mail",
	"Enter Password": "Digite a senha",
	"Username": "Nome de utilizador",
	"Nickname": "Utilizador",
	"Password": "Senha",
	"I forgot my password": "Esqueci a minha senha",
	"Password Reminder": "Lembrete de senha",
	"Enter you email": "Escreva o seu e-mail",
	"Your email address": "O seu e-mail",
	"Don't have an account?": "Ainda não tem uma conta?",
	"REGISTER": "REGISTAR-ME",
	"Password sent to your email": "Senha enviada ao seu e-mail",
	"This account has not been validated yet": "Esta conta ainda não foi validada",
	"LOGIN USING YOUR CREDENTIALS": "INTRODUZA OS SEUS DADOS",
	"FORGOT YOUR PASSWORD?": "ESQUECESTE A TUA SENHA?",
	"Login failed": "Falha na autenticação",
	"Username or password is incorrect": "Nome de utilizador ou senha está incorreta",

	"We're sorry!": "Lamentamos muito!",
	//registration
	"You're almost done!": "Estás quase pronto!",
	"Please fill your additional account information": "Por favor, preenche as informações adicionais da tua conta",
	"Just one step left!": "Falta apenas um passo!",
	"Let us learn more about you. Fill in your personal data": "Deixa-nos saber mais sobre ti. Preenche os teus dados pessoais",
	"Activate your account now.": "Ativa a tua conta agora.",
	"Activate your account now": "Ativa a tua conta agora.",
	"Please confirm your e-mail address": "Por favor, confirma o teu endereço de email",
	"Please fill in the following form to join {site_name} for free": "Por favor, preenche o formulário a seguir para participar de {site_name} gratuitamente",
	"CONTINUE": "CONTINUAR",

	"By clicking on the continue button I agree with the": "Ao clicar no botão continuar, concordo com os",
	"terms": "termos",
	"and cookies": "e cookies",

	"This site is protected by reCaptcha and the Google": "Este site é protegido pelo reCaptcha e pelo Google",
	"Privacy Policy": "Política de Privacidade ",
	"and": "e",
	"Terms of Service": "Termos de Serviço",
	"apply.": "do Google.",

	"Personal description": "Descrição pessoal",
	"optional": "opcional",
	"FINALIZE": "FINALIZAR",
	"We have just sent you a confirmation e-mail at": "Acabamos de enviar um e-mail de confirmação para",
	"To start using our website, please click on the activation link in this email.": "Para começar a usar nosso website, clique no link de ativação neste e-mail.",
	"Click here to verify your email": "Clica aqui para verificar o teu e-mail",
	"CLICK TO ACTIVATE": "CLICA PARA ATIVAR",
	"CLICK TO ACTIVATE - desktop": "CLICA PARA ATIVAR",
	"VERIFY MY EMAIL": "VALIDAR O MEU E-MAIL",
	"Not received any email yet?": "Ainda não recebeu nenhum e-mail?",
	"Please check your": "Por favor, verifique a sua caixa de",
	"spam": "spam",
	"box and wait at least 10 minutes or re-send activation": "e aguarde pelo menos 10 minutos ou reenvie a ativação.",
	"Enter Nickname": "Digite o Apelido",
	"Enter Your Name": "Digite seu Nome",
	"Password Required": "Senha requerida",
	"Enter valid Email": "Digite um e-mail válido",
	"Please enter at least 5 characters": "Por favor, insira pelo menos 5 caracteres",
	"Enter strong password": "Escreva uma senha forte.",
	"Email not available": "E-mail não disponível",
	"Nickname not available": "Apelido não disponível",
	"Maximum 4 number allowed": "Máximo de 4 números permitidos",
	"Name should not contain any space, number or special character": "O nome não deve conter nenhum espaço, número ou caractere especial",
	"Nickname should not contain any space or special characters": "O apelido não deve conter espaços ou caracteres especiais",
	"Your activation mail has been sent": "O teu e-mail de ativação foi enviado",
	"Complete your profile in a minute and start contacting people": "Completa o teu perfil num minuto e começa a entrar em contacto com as pessoas",
	"Complete your profile": "Completa o teu perfil",
	"Create Profile": "Criar Perfil",
	"Your description": "A tua subscrição",
	"Confirm your Email": "Confirma o teu e-mail",
	"We have sent you an email.": "Vamos enviar-te um e-mail.",
	"Check your email to complete your registration": "Verifica o teu e-mail para concluir o teu cadastro",
	"and be able to speak with other users.": "e poder falar com outros utilizadores.",
	"If you can not find it look it up in the spam or": "Se não encontras, procura na caixa de spam ou",
	"If you have not received it select one of the following options": "Se não recebeste, seleciona uma das seguintes opções",
	"junk folder": "spam",
	"Change Email": "Mude o e-mail",
	"Resend Email": "Reenviar e-mail",
	"Resend": "Reenviar",
	"New Email": "Novo e-mail",
	"Your activation key is invalid": "A tua chave de ativação é inválida",
	"Please include an '@' in the email address. '{email}' doesn't have an '@'": "Por favor, inclua um '@' no e-mail.  Ao '{email}' não consta o '@'.",

	//public pages
	"privacy": "privacidade",
	"terms-and-conditions": "termos-e-condições",
	"about-us": "sobre-nós",

	//forms
	"Name": "Nome",
	"Marital Status": "Estado Civil",
	"Date of Birth": "Data de nascimento",
	"Country": "País",
	"Location": "Região",
	"Province": "Região",
	"City": "Cidade",
	"Save Changes": "Guardar Alterações",
	"Select from list": "Selecionar da lista",
	"Day": "Dia",
	"Month": "Mês",
	"month": "Mês",
	"Year": "Ano",
	"Prefer not to say": "Prefiro não dizer",
	"Only profiles with photo": "Apenas perfis com foto",
	"This field is required": "Este campo é obrigatório",
	"Please update your profile": "Por favor, atualize seu perfil",
	"Profile successfully updated": "Perfil atualizado com sucesso",
	"The fields marked in red are required. Please fill them to continue.": "Os campos marcados em vermelho são obrigatórios. Por favor, preencha-os para continuar.",
	"Characters left": "Caracteres restantes",
	"Image attached": "Imagem anexada",
	"The message field is required.": "O campo mensagem é de preenchimento obrigatório.",
	"WITH PICTURE": "COM FOTO",
	"all age groups": "todas as idades",
	"EDIT DESCRIPTION": "EDITAR DESCRIÇÃO",
	"DESCRIPTION": "DESCRIÇÃO",
	"FEATURING": "DESTAQUE",
	"Please Select": "Por favor, selecione",
	"Emails do not match": "Emails não coincidem",
	"Your request was succesfully sent!": "A sua solicitação foi enviada com sucesso!",
	"Or send us a message directly": "Ou envia-nos uma mensagem diretamente",

	//user pages
	"My Profile": "O Meu Perfil",
	"Add Photos": "Adicionar Fotos",
	"Messages": "Mensagens",
	"Favorites": "Favoritos",
	"Search": "Pesquisa",
	"Settings": "Definições",
	"Visits": "Visitas",
	"Likes": "Favorito de outros",
	"Membership": "Estado",
	"Vip Members": "Membros VIP",

	"Account activation successful": "ATIVAÇÃO BEM-SUCEDIDA DA CONTA",
	"My chats": "As Minhas Conversas",

	//user home
	"Quick Search": "Procura rápida",
	"More Options": "Mais opções",
	"I am a": "Eu sou",
	"Looking for": "À procura",
	"Age": "Idade",
	"Search for": "Procurar",
	"All age groups": "Todos os grupos de idade",
	"Popular Members": "Membros Populares",
	"New Members": "Novos Membros",
	"Find a Match": "Realizar procura",
	"Recent Activity": "Atividade Recente",
	"Add Photo": "Adicionar Foto",
	"Back": "Voltar",
	"Become Elite": "Torne-se Elite",
	"Upgrade": "Melhorar",

	"likes": "Faíscas",
	"EDIT MY PROFILE": "EDITAR O MEU PERFIL",
	"CONFIGURATION": "CONFIGURAÇÃO",
	"ONLINE SUPPORT": "APOIO ONLINE",

	//user message
	"Trash": "Lixeira",
	"Delete Selected": "Excluir Selecionado",
	"No Messages Available": "Nenhuma mensagem disponível",
	"No Messages selected": "Nenhuma mensagem selecionada",
	"Back to inbox": "Voltar à caixa de entrada",
	"Back to messages": "Voltar para mensagens",
	"Select all": "Selecionar todos",
	"Deselect all": "Desmarcar todos",
	"Next Page": "Próxima Página",
	"Prev Page": "Página Anterior",
	"Type your message here": "Escreve aqui a tua mensagem",
	"Send message": "Enviar mensagem",
	"Send Icebreaker": "Enviar quebra-gelo",
	"VIEW PROFILE": "VER PERFIL",
	"DELETE": "ELIMINAR",
	"Chat with": "Conversar com",
	"Chat": "Conversa",

	//user favorites
	"My Favorites": "Meus favoritos",
	"No likes": "Não há favoritos",
	"Favorite added successfully": "Favorito adicionado com sucesso",
	"Favorite deleted successfully": "Favorito eliminado com sucesso",
	"Visit Profile": "Visitar Perfil ",

	//user visits
	"My Profile Visitors": "Visitas ao meu perfil",
	"No Visitors": "Sem visitas",

	//user photos
	"My pictures": "Minhas fotos",
	"See my public profile": "Ver meu perfil público",
	"Profile photo": "Foto de Perfil",
	"Profile Picture": "Imagem de Perfil",
	"2MB or lower.": "2MB ou menos.",
	"2MB or lower": "2MB ou menos",
	"Nudes not allowed in this category.": "Nudez não permitida nesta categoria.",
	"PHOTO ALBUM": "ÁLBUM DE FOTOS",
	"Allowed images 2 megabytes or less": "Permitidas imagens de 2 megabytes ou menos",
	"Add picture": "Adicionar foto",
	"Main reasons for rejection": "Principais motivos de rejeição",
	"Are you sure?": "Tem certeza?",
	"Are you sure you want to delete this image?": "Tem certeza de que deseja apagar esta imagem?",
	"Close": "Fechar",
	"Confirm": "Confirmar",
	"Attaching image": "Anexando imagem",
	"The message must be at least 2 characters.": "A mensagem precisa ter pelo menos 2 caracteres.",
	"Deleting...": "A eliminar...",
	"Pictures of underage people": "Fotos de menores de idade",
	"Personal data is visible": "Os dados pessoais são visíveis",
	"Fake or stolen pictures": "Fotos falsas ou roubadas",
	"Different gender to profile": "Género diferente do perfil",
	"Group pictures": "Fotos de grupo",
	"Weapons, drugs, violence": "Armas, drogas, violência ",
	"No people visible": "Nenhuma pessoa visível",
	"Info": "Informação",
	"We are reviewing your images. When they pass the verification process they will be shown to the other users.": "Estamos analisando as suas imagens. Após passarem pelo processo de verificação, serão mostradas aos outros utilizadores.",

	//user profiles
	"Profile": "Perfil",
	"yrs": "Anos",
	"years": "Anos",
	"yr from": "Anos",
	"Physique": "Anatomia",
	"Photos": "Fotos",
	"No Photos": "Sem Fotos",
	"About Me": "Sobre mim",
	"Characteristics": "Características",
	"Prefers": "Preferências",
	"Relationship": "Relacionamento",
	"Shape": "Corpo",

	//user edit profile
	"DESCRIBE YOURSELF": "DESCREVE-TE",
	"GENERAL": "GERAL",
	"(Will be verified)": "(Será verificado manualmente)",
	"Sex": "Sexo",
	"Seeking": "Buscando",
	"Finish your profile": "Finaliza o teu perfil",
	"Welcome! As your profile is new, we will give maximum visibility once you complete it.": "Bem-vindo! Como o seu perfil é novo, vamos dar-lhe visibilidade máxima assim que estiver completo.",

	//search profile
	"Advanced Search": "Procura Avançada",
	"OPEN TO": "ABERTO A",
	"PREFERENCES": "PREFERÊNCIAS",
	"CHARACTER": "PERSONALIDADE",
	"Search Result": "Resultados da Procura",
	"No Member found": "Nenhum membro encontrado",
	"Change Search": "Alterar Procura",
	"Hair Color": "Cor de cabelo",
	"Eye Color": "Cor de Olhos",
	"Body Type": "Tipo de Corpo",
	"Height": "Altura",
	"Weight": "Peso",
	"Smoker": "Fumador",
	"Ethnicity": "Etnia",
	"From": "De",
	"To": "Para",

	//settings
	"Change E-mail address": "Alterar Endereço de E-mail",
	"Your email address has been successfully updated": "Seu endereço de e-mail foi atualizado com sucesso",
	"Password": "Senha",
	"Account Status": "Estado da Conta",
	"Cancel": "Cancelar",
	"Delete Account": "Eliminar Conta",
	"Notifications": "Notificações",
	"Receive all notifications individually": "Receber todas as notificações individualmente",
	"Receive one daily email will all my notifications": "Receber um e-mail diário com todas as minhas notificações",
	"Receive a weekly email with all my notifications": "Receber um email semanal com todas as minhas notificações",
	"I don\'t want to receive any notifications": "Não quero receber notificações",
	"(we will send you a verification email)": "(vamos enviar-lhe um e-mail de verificação)",
	"Current Password": "Senha atual",
	"New Password": "Nova Senha",
	"Enter valid email": "Insira um e-mail válido",
	"Repeat New Password": "Repita a Nova Senha",
	"Save Settings": "Guardar Configurações",
	"Passwords should match": "As senhas devem corresponder",
	"Password length should be minimum 5 characters": "O comprimento da senha deve ter no mínimo 5 caracteres",
	"Attention: Your new email address must be verified by clicking on the link that we just sent you.": "Atenção: Seu novo endereço de e-mail deve ser verificado clicando no link que acabamos de enviar.",
	"I want to receive notifications by email": "Quero receber notificações por e-mail",
	"Status": "Status",
	"Renewal": "Renovação",
	"Auto renewal Canceled": "Renovação automática cancelada",
	"Delete profile": "Eliminar perfil",
	"Your account has been deleted. You will be redirected.": "A sua conta foi eliminada. Vai ser redirecionado.",
	"Successfully updated": "Atualizado com sucesso",
	"Current account status": "Status da conta atual",
	"Are you sure you want to cancel your subscription?": "Tem certeza de que deseja cancelar sua subscrição?",
	"Your subscription has been successfully canceled and will not be auto-renewed": "A sua subscrição foi cancelada com sucesso e não será renovada automaticamente",
	"Due to technical reasons, cancellation of the renewal of the trial must be made after 8 hours of the acquisition. For any other subscription, the cancellation must be done at least 8 hours after acquisition and before 48 hours of its expiration.": "Por motivos técnicos, o cancelamento da renovação do teste deve ser feito após 8 horas da aquisição.  Para qualquer outra subscrição, o cancelamento deve ser feito no mínimo 8 horas após a aquisição e antes de 48 horas do seu vencimento.",
	"Profile deleted. Redirecting...": "Perfil eliminado. Redirecionando...",
	"*Active subscriptions deleted this way will not be reimbursed": "*Subscrições ativas eliminadas desta forma não serão reembolsadas",
	"Are you sure you want to delete * your account? Your information will be permanently erased.": "Tem a certeza de que deseja eliminar a sua conta? As suas informações serão apagadas permanentemente.",
	"Settings successfully updated": "Configurações atualizadas com sucesso",
	"Disable Notification": "Desactivar notificaciones",
	"The notifications have been successfully disabled.": "As notificações foram desativadas com sucesso.",
	"Canceled Auto-Renewal": "Auto-Renuevo Cancelado",

	//subscription
	"Only Vip members can contact more than 5 different users per day.": "Apenas membros Vip podem contatar mais de 5 usuários diferentes por dia.",
	"Upgrade your status": "Faça um upgrade no seu status",
	"Enjoy our exclusive benefits and make new contacts": "Aproveite nossos benefícios exclusivos e faça novos contactos.",
	"Users Online": "Utilizadores online",
	"Discreet Billing. {site_name} will not appear in the invoice": "Faturamento discreto. {site_name} não aparecerá na fatura",
	"Month ::: Months": "Mês ::: Meses",
	"3 days trial": "3 dias de teste",
	"Try 3 days": "Escolha agora",
	"MEMBERSHIP": "SUBSCRIÇÃO",
	"Select": "Selecionar",
	"Send messages for 3 consecutive days": "Envie mensagens por 3 dias consecutivos",
	"Only one trial subscription per user": "Apenas uma subscrição teste por utilizador",
	"In order to offer better service without interruptions, all plans will be auto renewed at the cost of one of the following subscriptions based on the pack chosen above.": "Para oferecer um melhor serviço sem interrupções, todos os planos serão renovados automaticamente ao custo de uma das seguintes assinaturas com base no pacote escolhido acima.",
	"for": "para",
	"For full access to all details of events, including how to cancel it,": "Para acesso total a todos os detalhes dos eventos, incluindo como cancelá-los,",
	"click here": "clique aqui",
	"for terms and conditions,": "Para termos e condições,",
	"All profiles are reviewed and moderated by our team.": "Todos os perfis são revisados e moderados pela nossa equipa.",
	"CURRENT PLAN": "PLANO ATUAL",
	"Basic (no trial bonus) You do not have any active pack at this moment and you are a BASIC user. To increase your chances of connecting with and contacting other users, you will need to upgrade to Elite or VIP status.": "Básico (sem bónus de teste) Não tem nenhum pacote ativo neste momento e é um utilizador BASIC. Para aumentar as suas possibilidades de conectar e entrar em contacto com outros utilizadores, necessita atualizar o estadi Elite ou VIP.",
	"MY BENEFITS": "MEUS BENEFÍCIOS",
	"{discount} cheaper": "{discount} mais barato",
	"save": "poupar",
	"Create my profile": "Criar o meu perfil",
	"Read messages": "Ler mensagens",
	"Add to Favorites": "Adicionar a Favoritos",
	"Make yourself visible to other users by adding them to favorites": "Torne-se visível para outros utilizadores adicionando-os aos favoritos",
	"Photo Album": "Álbum de fotos",
	"No fake pictures allowed": "Não são permitidas fotos falsas",
	"Album pictures": "Fotos do álbum",
	"Show yourself and increase your possibilities": "Mostre-se e aumente suas possibilidades",
	"Detailed profile search": "Procura detalhada de perfis",
	"Find exactly what you are looking for": "Encontra exatamente o que estás à procura",
	"See other user's pics": "Ver fotos de outros utilizadores",
	"Choose your favorite": "Escolhe quem gostas mais",
	"Reply to and send messages": "Responder e enviar mensagens",
	"Make direct contact with up to five users per day": "Faça contacto direto com até cinco utilizadores por dia",
	"Be shown first in searches": "Aparece em primeiro lugar nas pesquisas",
	"Your profile will be featured before the rest of the users": "O teu perfil vai ser destacado do resto dos utilizadores",
	"Make direct contact with unlimited users": "Faz contacto direto com um número ilimitado de utilizadores ",
	"Choose payment method": "Escolha o método de pagamento",
	"LESS THAN": "MENOS DE",
	"PER DAY": "POR DIA",
	"DISCRETION": "DISCRIÇÃO",
	"Our website name will not be disclosed": "O nome do nosso site não será divulgado",
	"Our name will not be disclosed": "Nosso nome não será divulgado",
	"100% Anonymous.": "100% Anónimo.",
	"Choose your subscription": "Escolhe a tua subscrição",
	"Recommended": "Recomendado",
	"Most Popular": "Mais popular",
	"Secure payments with": "Pagamentos seguros com",
	"Your message has NOT been sent. You need to upgrade your status in order to send messages.": "A sua mensagem NÃO foi enviada. Precisa fazer upgrade no seu status para enviar mensagens.",
	"Your purchase has been successful": "A sua compra foi bem sucedida",
	"All subscriptions automatically renew to ensure optimum service. If you want to change the settings you can do it": "Todas as subscrições vão ser renovadas automaticamente para garantir um serviço ideal. Se quiser alterar as configurações, podes fazê-lo",
	"here.": "aqui.",
	"after_here": "",

	//ad
	"Become a": "Converta-se em",
	"PREMIUM MEMBER": "MEMBRO PREMIUM",
	"And contact": "E entre em contacto com",
	"more people": "mais pessoas",

	"upgrade": "Fazer upgrade",
	"Boost": "Promovad",
	"boost": "promova",
	"your profile": "o teu perfil",
	"Start chatting and meet more people": "Comece a conversar e conheça mais pessoas",
	"And get": "E consiga",
	"more views": "mais visualizações",

	"Now +3 extra Credits with each purchase": "Agora +3 Créditos de presente a cada compra",
	"Best Seller": "Mais vendidos",
	"Select your Package": "Selecione seu pacote",
	"Less than {amount} per credit": "Menos de {amount} por crédito",
	"Less than": "Menos de",
	"per credit": "por crédito",
	"Free credits": "Créditos grátis",
	"per purchase": "a cada compra",
	"Premium Status doesn’t allow free messages.": "O Status Premium não permite mensagens gratuitas.",
	"Chat now": "Conversar Agora",
	"Get Credits": "Obter créditos",
	"Credit": "Crédito",
	"Credits": "Créditos",
	"My credits": "Os Meus Créditos ",
	"{count} Credits left": "{count} Créditos disponíveis",
	"Your message has not been sent, you need credits to be able to send messages.": "A Tua mensagem não foi enviada, precisas de créditos para poder enviar mensagens.",
	"Icebreaker": "Quebra-gelo",
	"Icebreaker today": "Quebrar gelo hoje",
	"The cost for sending an Icebreaker is 2 credits. The Icebreaker can only be used to start a conversation or in response to another icebreaker.": "O custo para enviar um quebra-gelo é de 2 créditos.  O quebra-gelo só pode ser usado para iniciar uma conversa ou em resposta a outro quebra-gelo.",
	"With the acquisition of each package, users make a purchase of a pack of credits that can be used to send messages. The ratio of credits per message is ten to one, that means, the user needs to have at least ten credits in his account to send a message. The cost for sending an Icebreaker is 2 credits.": "Com a aquisição de cada pack, os utilizadores compram um pacote de créditos que podem ser usados para enviar mensagens. A proporção de créditos por mensagem é de dez para um, ou seja, o utilizador precisa ter pelo menos dez créditos na sua conta para enviar uma mensagem. O custo para enviar um quebra-gelo é de 2 créditos.",

	"Trial pack": "Pacote de teste",
	"Only one trial pack per user allowed": "Permitido apenas um pacote de teste por utilizador",
	"Used": "Utilizado",

	"Exclusive Promotion": "Promoción Exclusiva",
	"Account Deleted": "Conta Eliminada",
	"Log out Successful": "Sessão encerrada com sucesso",

	"Do you like FREE LIVE SEX?": "Gostas de SEXO AO VIVO GRÁTIS?",
	"Enter this code to enjoy a 10% discount:": "Insira este código para usufruir de um desconto de 10%:",
	"Discount redeemable for your first online purchase. Expires in 2 hours.": "Desconto válido na sua primeira compra online. Expira em 2 horas.",

	"This account has been deleted.": "Esta conta foi eliminada.",
	"Our algorithm has found a better option in line with your taste.": "Nosso algoritmo encontrou uma opção melhor de acordo com as suas preferências.",
	"You will be redirected in a few seconds.": "Vai ser redirecionado num instante.",

	"Has added you to favorites": "Adicionou-te aos favoritos",
	"Is looking at your profile": "Está a ver o teu perfil",
	"See profile": "Ver perfil",
	"See message": "Ver mensagem",

	"I think I am in love": "Acho que estou apaixonada",
	"Hey! I'm winking at you": "Ei! Estou a piscar para ti.",
	"Tell me more about you. Fancy a chat?": "Conta-me mais sobre ti. Queres conversar?",
	"Some flowers for you!": "Umas flores para você!",
	"Sending you a kiss": "Mando-te um beijo",
	"Surprise, it\'s me!": "Surpresa, sou eu!",
	"Hi, how are you?": "Olá, como estás?",
	"Let's get naughty": "Vamos fazer safadezas",

	"Get 5 times the regular response rate. Premium members benefits:": "Obtenha 5 vezes a taxa de resposta normal. Benefícios dos membros premium:",
	"Appear high up on searches": "Apareça no topo das pesquisas",
	"Distinctive badge that increases your visibility": "Selo diferenciado que aumenta  a tua visibilidade",
	"5 Free Icebreakers a day": "5 quebra-gelos grátis por dia",
	"In addition to this, a voucher for a 15% Webcam discount": "Além disso, um voucher de 15% de desconto na webcam",

	"The Icebreaker can only be used to start a conversation or in response to another icebreaker.": "O quebra-gelo só pode ser usado para iniciar uma conversa ou em resposta a outro quebra-gelo.",

	"Succesful purchase": "Compra bem-sucedida",
	"Your purchase has been successful.": "A tua compra foi bem sucedida.",
	"It can take a few minutes to update.": "Pode levar alguns minutos para atualizar.",
	"You will be redirected.": "Vai ser redirecionado.",

	"Changes can take some minutes to update.": "As alterações podem levar alguns minutos para serem atualizadas.",

	"Yearly Package": "Pacote anual",
	"Package": "Pacote",
	"Buy Now": "Comprar Agora",
	"The Elite status allows you to contact up to 5 different users every day by sending them unlimited messages.": "O status Elite permite entrar em contacto com até 5 utilizadores diferentes todos os dias, enviando-lhes mensagens ilimitadas.",

	"Exclusive offer": "Oferta exclusiva",
	"Welcome": "Bem-vindo ",
	"Mary from {domain_name} support": "Lorena do apoio de {domain_name}",
	"Welcome to {domain_name}. We hope you find what you are looking for.": "Bem-vindo a {domain_name}.  Esperamos que encontres o que estás à procura.",
	"Here are some Pro Tips to be more successful and meet people:": "Aqui estão alguns truques profissionais para ter mais sucesso e conhecer pessoas:",
	"Upload a profile picture to get more connections.": "Carregue uma foto de perfil para obter mais conexões.",
	"Send an icebreaker to get a conversation easily started.": "Envie um quebra-gelo para iniciar uma conversa facilmente.",
	"If you like someone don’t forget to add them to favorites so they will know.": "Se gostas de alguém, não te esqueças de o adicionar aos favoritos para que ele saiba.",
	"And of course, if you like somebody send a message and ask for a date!": "E claro, se gostares de alguém manda uma mensagem e convida para um encontro!",
	"If you need any extra help, please contact our ": "Se precisares de ajuda extra, entra em contacto com nosso ",
	"support": "apoio",
	". We will be happy to help you.": ". Ficaremos felizes de ajudar-te.",
	"Try your luck": "Tenta a tua sorte!",
	"64% of our users have a date in the first 2 weeks.": "64% dos nossos utilizadores têm um encontro nas primeiras 2 semanas.",
	"Good Luck!.": "Boa sorte!",
	"*This message will be deleted upon closing.": "*Esta mensagem será apagada ao ser fechada.",

	"Private picture": "Foto privada",
	"Private pictures will appear blurred out. Only users that have already paid will be able to see them.": "As imagens privadas vão aparecer desfocadas. Somente os utilizadores que tenham feito o pagamento prévio poderão vê-las.",
	"Name change": "Alterar nome",
	"If you want to change your name or nickname, please contact our support team and we will do it within 24h.": "Se deseja alterar seu nome ou apelido, entre em contacto com nossa equipa de apoio e vai ser feito em 24h.",
	"See all images": "Ver todas as fotos",
	"Unlock all private photos forever for just 20 credits": "Desbloqueie todas as fotos privadas para sempre por apenas 20 créditos",
	"Unlock forever all private pictures": "Desbloqueie para sempre todas as fotos privadas",

	"This email address is not registered, please sign up to log in.": "Este endereço de e-mail não está registrado. Por favor, registre-se para fazer login.",
	"Congratulations, your account is now active.": "Parabéns, sua conta agora está ativa.",
	"Complete one last step and have fun!": "Complete uma última etapa e divirta-se!",
	"OR": "OU",

	"We sent you an email with a link to activate your account valid for the next 12 hours to:": "Enviamos um e-mail com um link para ativar sua conta válido pelas próximas 12 horas para:",
	"If you haven\'t received it in your inbox or ": "Se não recebeu na sua caixa de entrada ou ",
	"or you want to modify the email address, click on the button below.": "ou deseja modificar o endereço de e-mail, clique no botão abaixo.",
	"We sent you an email to verify your account, the email will be valid for the next 12 hours:": "Enviamos um e-mail para verificar sua conta. O e-mail será válido pelas próximas 12 horas:",
	"If you can\'t find it in your inbox or spam, you can edit or resend it": "Se não o conseguir encontrar na sua caixa de entrada ou spam, poderá editá-lo ou reenviá-lo",
	"Resend or change email": "Reenviar ou alterar e-mail",
	"Please use the same email you registered.": "Por favor, use o mesmo e-mail que registou.",
	"Connect with Google": "Conecte-se com o Google",
	"Connect with Yahoo": "Conecte-se com o Yahoo",
	"Connect with Microsoft": "Conecte-se com o Hotmail",
	"Or turn back": "Ou volte",
	"Complete your registration": "Completa o teu registo",

	"It will not be shared": "Não vai ser partilhado",
	"It will never be shared. 100% Privacy": "Nunca vai ser partilhado. 100% de privacidade",

	"Unlock all private pictures forever.": "Desbloqueie todas as fotos privadas para sempre.",
	"Unlock": "Desbloquear",

	"You will be redirected in a second": "Vai ser redirecionado em alguns segundos ",

	"Card payment": "Pagamento com cartão",
	"Bank transfer": "Transferência bancária",
	"Prepaid card": "Cartão pré-pago",
	"SofortBanking": "Transferência bancária",

	"Error": "Erro",
	"page not found": "Página não encontrada",
	"Back to homepage": "Voltar à página inicial",

	"per month": "por mês",
	"week": "semana",
	"{count} week": "{count} semana",

	"Your profile has been blocked": "O seu perfil foi bloqueado",
	"In order to offer the best possible user experience, we block all profiles that haven\'t sent a message within 21 days of registration.": "Para oferecer a melhor experiência possível aos nossos utilizadores, bloqueamos todos os perfis que não enviaram nenhuma mensagem até 21 dias após o registo.",
	"Please acquire a credit package to unlock your profile and restore all its features.": "Adquira um pacote de crédito para desbloquear o seu perfil e restaurar todos os seus recursos.",
	"Restore access to:": "Restaurar o acesso a:",
	"All messages": "Todas as mensagens",
	"Users search": "Procurar utilizadores",
	"Your contacts": "Seus contactos",
	"All favourites": "Todos os favoritos",
	"All pictures": "Todas as fotos",
	"Send Icebreakers": "Enviar quebra-gelo",
	"Unblock Profile": "Desbloquear perfil",

	"This website uses its own and third-party cookies and identifiers to improve accessibility and analyse our traffic. If you continue browsing, we consider that you accept its use. For more information visit our": "Este website utiliza cookies e identificadores próprios e de terceiros para melhorar a acessibilidade e analisar nosso tráfego. Se continuar a navegar consideramos que aceita a sua utilização. Para mais informações aceda à nossa",
	"Cookies Policy": "Política de Cookies",

	"DATE GUARANTEE": "GARANTIA DE UM ENCONTRO",
	"Take advantage of our Date guarantee": "Aproveite a nossa garantia de encontros",
	"No date? Money Back!": "Sem um encontro? O seu dinheiro de volta!",
	"No risk": "Sem risco",
	"Guarantee of success": "Garantia de sucesso",
	"Terms and Conditions": "Termos e Condições",

	"Boost your visibility": "Aumente sua visibilidade",
	"SAVE": "ECONOMIZE",
	"Add 3 months premium": "Adicione 3 meses de subscrição Premium",
	"Add {months} months premium": "Adicione {months} meses premium",

	"We guarantee you a real date.": "Garantimos um encontro real.",
	"YES, I WANT A DATE": "SIM, QUERO UM ENCONTRO",

	"Terms and conditions of the date guarantee. In order to fulfill all refund conditions, you must have a fully completed profile for a period of at least six months and your personal description in your profile. Your profile must contain at least one approved picture throughout this period. Furthermore, each week, you must contact at least seven different members located close to you (radius of 100km) within your age range (-5/+5 years). If you fulfill all the conditions listed above and you still were not able to find a date, we will full refund all the payments you made during this time. This guarantee is only valid for the first 12 months following your registration. Good luck!": "Termos e condições da Garantia de um Encontro. Para cumprir todas as condições de reembolso, deve ter um perfil totalmente preenchido por um período de pelo menos seis meses e ter a sua descrição pessoal no seu perfil. O seu perfil deve conter pelo menos uma foto aprovada ao longo desse período. Além disso, todas as semanas, deve entrar em contacto com pelo menos sete membros diferentes localizados perto de si (raio de 100 km) dentro da sua faixa etária (-5/+5 anos). Se cumprir com todas as condições listadas acima e ainda não conseguir marcar um encontro, reembolsaremos integralmente todos os pagamentos que fez durante esse período. Esta garantia só é válida durante os primeiros 12 meses após o seu registo. Boa sorte!",

	"You have {count} days premium left": "Tem {count} dias premium restantes",
	"Check our special offer before deleting your account!": "Aproveite nossa oferta especial antes de excluir sua conta!",
	"Delete my account": "Eliminar a minha conta",
	"Free": "Grátis",

	"Premium, Elite and VIP packages are auto-renewable, which means they will auto-renew when they expire.": "Os pacotes Premium, Elite e VIP são auto-renováveis, o que significa que serão renovados automaticamente quando expirarem.",
	"and can confirm that I am 18 years or older.": "e posso confirmar que tenho 18 anos ou mais.",

	"COMPLETE YOUR PURCHASE": "COMPLETE SUA COMPRA",
	"Payment details": "Detalhes do pagamento",
	"Total": "Total",
	"3-digit number on the back of your credit card": "Número de 3 dígitos no verso do seu cartão de crédito",
	"MM / YY": "MM / AA",
	"MM": "MM",
	"YY": "AA",
	"Name on card": "Nome no cartão",
	"Charges will appear discreetly as tpmpsup.com": "As cobranças aparecerão discretamente como tpmpsup.com",
	"Buy Now": "Compre Agora",
	"Premium package 3 Months": "Pacote Premium 3 Meses",

	"This purchase was declined. Please check your details and try again.": "Esta compra foi recusada. Por favor, verifique os seus dados e tente novamente.",
	"Your purchase was succesful.": "A sua compra foi bem sucedida.",

	"SPECIAL OFFER": "OFERTA ESPECIAL",
	"Unlock your account with our special offer": "Desbloqueie a sua conta com a nossa oferta especial",
	"Take advantage of our special offer to unlock your profile and restore all its features.": "Aproveite a nossa oferta especial para desbloquear o seu perfil e restaurar todos os seus recursos.",

	"card_number_require": "O número do cartão é obrigatório",
	"card_number_invalid": "Número de cartão inválido",
	"card_name_require": "O nome do titular do cartão é obrigatório",
	"card_name_invalid": "O nome do titular do cartão é inválido",
	"card_cvc_require": "O CVC é obrigatório",
	"card_cvc_invalid": "CVC inválido",
	"card_expiry_require": "A data de validade do cartão é obrigatória",
	"card_expiry_invalid": "A data de validade do cartão não é válida",

	"Private photo": "Foto privada",
	"Search for someone now": "Encontre pessoas agora",
	"Edit": "Editar",

	"Locked Album": "Álbum bloqueado",
	"Get credits to unlock all albums": "Obtenha créditos para desbloquear todos os álbuns",
	"picture ::: pictures": "foto ::: fotos",
	"Open": "Abrir",

	"Receive notifications": "Receber notificações",
	"I want to be notified when I receive a message": "Quero ser avisado quando receber uma mensagem",
	"No": "Não",
	"Yes": "Sim",

	"logout_confirm_title": "Terminar sessão", 
	"Are you sure you want to log out?": "Tens a certeza de que queres terminar a sessão?",

	"Your payment has been cancelled": "O seu pagamento foi cancelado",
	"Something went wrong, please try again in 10 minutes": "Falhou alguma coisa. Por favor, tente novamente em 10 minutos",

	"Please accept the terms and conditions to continue": "Por favor, aceite os termos e condições para continuar",
	"By checking this box and clicking continue I accept the": "Ao marcar esta caixa e clicar em continuar, aceito os",
	"rg_terms": "Termos e Condições",
	"rg_privacy": "Privacidade",
	"rg_cookies": "Cookies",
	"I am aware of the use of fictitious profiles on the website and I confirm that I am of legal age.": "Estou ciente do uso de perfis fictícios no site e confirmo que sou maior de idade.",

	"Cookie": "Cookie",
	"Responsible Party": "Responsável",
	"Category": "Categoria",
	"Cookie Name": "Nome do Cookie",
	"Purpose": "Propósito",
	"Lifespan": "Duración",
	"Type of Cookie": "Tipo de Cookie",
	"Strictly necessary": "Estritamente necessários",
	"Functional": "Funcional",
	"Performance": "Rendimento",
	"Advertising": "Publicidade",
	"cookie-policy": "politica-de-cookies",
	"cookie policy": "política de cookies",

	"We Care About Your Privacy": "A tua Privacidade importa",
	"This site uses its own, technical and third party cookies to improve navigation performance, analyzing visitors behaviour to help us and our marketing partners measure campaign results and personalized advertising. By clicking \"Accept All\" you are agreeing to all of these cookies.": "Este website usa cookies próprios, técnicos e de terceiros para melhorar o desempenho da navegação, analisando o comportamento dos visitantes para ajudar-nos e aos nossos parceiros de marketing a medir os resultados da campanha e a publicidade personalizada. Ao clicar em &quot;Aceitar tudo&quot; concorda com todos esses cookies.",
	"Accept All": "Aceitar todos",
	"Cookies Settings": "Configuração de Cookies",
	"About your privacy": "Sobre sua privacidade",
	"We use cookies for traffic balancing, session identification, analytics and marketing attribution. You can opt-in or opt-out of any or all of them from this panel." : "Usamos cookies para balanceamento de tráfego, identificação de sessão, análise e atribuição de marketing.  Pode ativar ou desativar qualquer um ou todos eles neste painel.",
	"You will be able to change your set up at any time from our :cookies_policy page": "Pode alterar a sua configuração a qualquer momento na nossa página de :cookies_policy",
	"Accept all": "Aceitar todos",
	"Manage Consent Preferences": "Gestionar preferências de consentimento",
	"Technical (Mandatory)": "Técnico (Obrigatório)",
	"Performance (Monitor website performance)": "Rendimento (Monitorizar o desempenho do site)",
	"Functional (User experience improvement)": "Funcional (Melhoria da experiência do utilizador)",
	"Advertising (Marketing measurement)": "Publicidade (Medições de marketing)",
    "Advertising (Personalization)": "Publicidade (Personalização)",
	"Save Selection": "Guardar Seleção",
	"Reject All": "Rejeitar todos",

	"We and our partners process data to provide:": "Nós e os nossos parceiros processamos dados para proporcionar:",
	"Use precise geolocation data. Actively scan device characteristics for identification. Store and/or access information on a device. Personalised ads and content, ad and content measurement, audience insights and product development.": "Utilize dados precisos de geolocalização. Analise ativamente as características do dispositivo para identificação. Armazene e/ou aceda  a informações num dispositivo. Personalize anúncios e conteúdos, medições de anúncio e conteúdo, informações sobre a audiência e desenvolvimento de produtos.",
	"List of partners": "Lista de sócios",
	"Cookie Policy": "Política de Cookies",
	"These cookies are used in connection with the tracking of actions related to advertisements. For example, these cookies will remember that you have visited {name} and can be used to show you a personalized advertisement on another website. Please check the following links of the platforms where we promote ourselves, Google, Microsoft and Twitter. for more information.": "Esses cookies são usados em conexão com o rastreamento de ações relacionadas a anúncios. Por exemplo, esses cookies vão lembrar-lhe que visitou {name} e podem ser usados para mostrar um anúncio personalizado noutro site. Por favor, verifique os seguintes links das plataformas onde nos promovemos, Google, Microsoft e Twitter, para mais informações.",
	"Delete cookies": "Eliminar Cookies",
	"Save selection": "Guardar Seleção",
	"Reject all": "Rejeitar todos",

	"{name} hasn't replied to you yet. Send another message!": "{name} ainda não te respondeu. Envia outra mensagem!",
	"Validate my Ice-breaker": "Validar meu quebra-gelo",

	"Distance": "Distância",
	"now": "ahora",

	"Starting  June 1, 2023 onwards, we will not process payments with PaySafeCard.": "A partir de 01 de Junho de 2023 para a frente, não será possível continuar a efetuar pagamentos com o PaySafeCard.",

	"Dear User,": "Estimado utilizador,",
	"Thanks for your interest in joining our website, but, in order to keep a gender balance close to 50/50 men and women, registration is not allowed for men, at the moment.": "Agradecemos o teu interesse em fazer parte do nosso website, mas de momento, as inscrições estão fechadas aos homens, uma vez que precisamos de encontrar um equilíbrio de género próximo dos 50%.",
	"If you still want a date, click on the button below and try one of the best websites online.": "Se ainda quiseres marcar um encontro, clica no botão e experimenta um dos melhores websites online.",
	"Try Now": "Experimentar agora",
	
	"Your account has been deleted.": "A tua conta foi eliminada.",
	"According to your choices, our algorithms have found a better option for you.": "O nosso algoritmo encontrou uma opção muito melhor para ti, de acordo com as tuas escolhas.",
	"See More": "Ver mais",

	"10 credits for you!": "10 créditos para ti!",
	"Get 3 free Icebreakers": "Consegue 3 quebra-gelos grátis",
	"Free Icebreakers": "Quebra-gelos grátis",
	"Congratulations, you have received 3 Icebreakers!": "Parabéns, ganhaste 3 quebra-gelos!",

	"Apple pay only works with the Safari browser. Please open your Safari browser and login to your account in order to proceed with this payment.": "O Apple Pay só funciona a partir do browser Safari. Abre o Safari e inicia sessão na tua conta para efetuares o pagamento.",

	"No nudity": "Sem nudez",
	"Nudity allowed": "Nudez permitida",

	"Edit profile": "Editar perfil",
	"Online": "Online",
	"Step": "Próxima etapa",
	"Completed": "Concluído",
	"Help": "Ajuda",
	"or Drag and drop": "ou arrastar e largar",

	"You will be able to send messages in": "Poderás enviar mensagens em",
	"Send": "Enviar",
	"Success": "Sucesso",
	"Start a conversation with {name}": "Iniciar uma conversa com {name}",
	"Failed": "Fehlgeschlagen",
	"Congratulations, your account has been successfully created.": "Parabéns, sua conta foi criada com sucesso.",
	"Contact our support": "Kontaktieren Sie unseren Support",
	
	"About": "Sobre",
	"Scale": "Ajustar",
	"Rotate": "Rodar",

	"No favorites added": "Nenhum favorito adicionado",

	"Didn\'t find what you were looking for?": "Não encontras o que procuras?",
	"Please send us an email and we will get back to you.": "Envia-nos um e-mail e entraremos em contacto contigo o mais rapidamente possível.",
	"Or contact us using the form": "Ou contacta-nos utilizando o formulário abaixo."

}
