export default {
	"Already a member?": "Jesteś już członkiem?",
	"Login": "Zaloguj się",
	"Log out": "Wyloguj się",
	"Your current status": "Twój obecny status",
	"BASIC": "BASIC",
	"Basic": "Basic",
	"Bronze": "Brązowy",
	"Elite": "Elite",
	"VIP": "VIP",
	"Join Us": "Dołącz do nas",
	"Find people near you": "Znajdź osoby w pobliżu",
	"Join now": "Dołącz bezpłatnie",
	"I am a": "Jestem",
	"Email": "E-mail",
	"It will not be published": "Nie zostanie opublikowany",
	"E-mail address": "Adres e-mail",
	"SEARCH NOW": "WYSZUKAJ TERAZ",
	"I confirm that I am over 18 years old": "Potwierdzam, że mam ukończone 18 lat.",
	"Do not just think about it! Discover the hottest dating site in Australia. Every day thousands of people like you join us.": "Nie zastanawiaj się! Odkryj najgorętszy serwis randkowy w Polsce. Każdego dnia dołączają do nas tysiące ludzi takich jak Ty.",
	"Information": "Informacje",
	"All rights reserved.": "Wszelkie prawa zastrzeżone.",
	"SUBSCRIPTION": "SUBSKRYPCJA",
	"Other": "Inne",
	"All fields are required": "Wszystkie pola są wymagane",
	"The fields marked in Red are required. Please fill them to continue.": "Pola zaznaczone na czerwono są wymagane. Wypełnij je, aby kontynuować.",
	"If you did not find a suitable answer please contact us": "Jeśli nie znalazłeś odpowiedniej odpowiedzi, skontaktuj się z nami",
	"Will help us to identify you": "Pomóż nam Cię zidentyfikować",
	"Invalid Username": "Nieprawidłowa nazwa użytkownika",
	"Subject": "Temat",
	"Tell us how can we help you": "Powiedz nam, jak możemy Ci pomóc",
	"Please enter subject": "Wpisz temat",
	"Please enter at least 10 characters": "Wpisz co najmniej 10 znaków",
	"Connected to your account": "Połączono z Twoim kontem.",
	"Repeat Email": "Powtórz e-mail",
	"Emails did not match": "Adresy e-mail nie zgadzają się",
	"Message": "Wiadomość",
	"No Recent Activity": "Brak ostatnio aktywności",

	//route labels
	"Home": "Strona główna",
	"Contact": "Kontakt",
	"Support": "Pomoc",
	"Affiliates": "Partnerzy",
	"Privacy": "Polityka Prywatności",
	"Terms": "Regulamin",

	//routes uri
	"/login": "/zaloguj-sie",
	"/contact": "/kontakt",
	"/support": "/pomoc",
	"/affiliates": "/partnerzy",
	"/privacy": "/prywatnosc",
	"/terms-and-conditions": "/warunki-i-zasady",
	"/cookie-policy": "/polityka-cookie",
	"/subscription/exit": "/subskrypcja/wyjscie",
	"/purchase-confirmation": "/potwierdzenie-zakupu",
	"/page/:page_name": "/strona/:page_name",

	"/dashboard": "/strona-glowna",
	"/mobile-dashboard": "/mobliny-panel-sterowania",
	"/activate-profile/:token": "/aktywuj-profil/:token",
	"/edit-profile": "/edytuj-profi",
	"/profile/:name": "/profil/:name",
	"/my-picture": "/moje-zdjecie",
	"/messages/inbox": "/wiadomosci/skrzynka-odbiorcza",
	"/messages/trash": "/wiadomosci/kosz",
	"/messages/send/:name": "/wiadomosci/wyslij/:name",
	"/visitor": "/odwiedzajacy",
	"/favorite/my": "/ulubione/moje",
	"/favorite/others": "/ulubione/inne",
	"/search": "/wyszukaj",
	"/search-result": "/wynik-wyszukiwania",
	"/settings": "/ustawienia",
	"/validate-new-email-address/:token": "/zweryfikuj-nowy-adres-e-mail/:token",
	"/subscription": "/subskrypcja",
	"/credits": "/kredyty",
	"/elite": "/elite",
	"/faq": "/pytania-i-odpowiedzi",
	"/delete-account": "/usun-konto",

	"/exclusive-promotion": "/ekskluzywna-promocja",
	"/account-deleted": "/konto-usuniete",
	"/logged-out": "/wylogowano",

	//login
	"Login": "Zaloguj się",
	"Enter username or Email": "Wpisz nazwę użytkownika lub e-mail",
	"Enter Password": "Wpisz hasło",
	"Username": "Nazwa Użytkownika",
	"Nickname": "Nickname",
	"Password": "Hasło",
	"I forgot my password": "Zapomniałem hasła",
	"Password Reminder": "Przypomnienie hasła",
	"Enter you email": "Wpisz swój adres e-mail",
	"Your email address": "Twój adres e-mail",
	"Don't have an account?": "Nie masz konta?",
	"REGISTER": "ZAREJESTRUJ SIĘ",
	"Password sent to your email": "Hasło zostało wysłane na Twój e-mail",
	"This account has not been validated yet": "To konto nie zostało jeszcze zweryfikowane",
	"LOGIN USING YOUR CREDENTIALS": "ZALOGUJ SIĘ KORZYSTAJĄC Z DANYCH",
	"FORGOT YOUR PASSWORD?": "ZAPOMNIAŁEŚ HASŁA?",
	"Login failed": "Logowanie nie powiodło się",
	"Username or password is incorrect": "Nazwa użytkownika lub hasło jest niepoprawne",

	"We're sorry!": "Przepraszam",
	//registration
	"You're almost done!": "Już prawie gotowe!",
	"Please fill your additional account information": "Podaj dodatkowe informacje o koncie",
	"Just one step left!": "Został już tylko jeden krok!",
	"Let us learn more about you. Fill in your personal data": "Pozwól nam dowiedzieć się więcej o Tobie. Uzupełnij swoje dane personalne",
	"Activate your account now": "Aktywuj swoje konto teraz",
	"Activate your account now.": "Aktywuj swoje konto teraz.",
	"Please confirm your e-mail address": "Potwierdź swój adres e-mail",
	"Please fill in the following form to join {site_name} for free": "Wypełnij poniższy formularz, aby bezpłatnie dołączyć do {site_name}",
	"CONTINUE": "KONTYNUUJ",

	"By clicking on the continue button I agree with the": "Klikając przycisk \"Kontynuuj\" akceptujesz",
	"terms": "warunki",
	"and cookies": "i ciasteczka",

	"This site is protected by reCaptcha and the Google": "Ta strona jest chroniona przez reCaptcha i Google",
	"Privacy Policy": "Polityka prywatności",
	"and": "i",
	"Terms of Service": "Warunki korzystania z usługi",
	"apply.": "zastosuj.",

	"Personal description": "Opis personalny",
	"optional": "opcjonalnie",
	"FINALIZE": "ZAKOŃCZ",
	"We have just sent you a confirmation e-mail at": "Właśnie wysłaliśmy Ci wiadomość e-mail z potwierdzeniem na adres",
	"To start using our website, please click on the activation link in this email.": "Aby rozpocząć korzystanie z naszej strony internetowej, kliknij link aktywacyjny w wiadomości e-mail.",
	"Click here to verify your email": "Kliknij tutaj, aby zweryfikować swój adres e-mail",
	"CLICK TO ACTIVATE": "KLIKNIJ, ABY AKTYWOWAĆ",
	"CLICK TO ACTIVATE - desktop": "KLIKNIJ, ABY AKTYWOWAĆ",
	"VERIFY MY EMAIL": "SPRAWDŹ POCZTĘ",
	"Not received any email yet?": "Nie otrzymałeś jeszcze żadnej wiadomości?",
	"Please check your": "Proszę, sprawdź swoją",
	"spam": "skrzynkę spam",
	"box and wait at least 10 minutes or re-send activation": "i odczekaj co najmniej 10 minut lub wyślij ponownie probę o aktywację.",
	"Enter Nickname": "Wpisz Nickname",
	"Enter Your Name": "Wpisz swoje imię",
	"Password Required": "Wymagane hasło",
	"Enter valid Email": "Wprowadź poprawny e-mail",
	"Please enter at least 5 characters": "Wpisz co najmniej 5 znaków",
	"Enter strong password": "Wpisz silne hasło",
	"Email not available": "Niedostępny adres e-mail ",
	"Nickname not available": "Nazwa użytkownika jest niedostępna",
	"Maximum 4 number allowed": "Maksymalnie dozwolone są 4 cyfry",
	"Name should not contain any space, number or special character": "Nazwa nie powinna zawierać spacji, numer lub znak specjalny",
	"Nickname should not contain any space or special characters": "Nickname nie powinien zawierać spacji ani znaków specjalnych",
	"Your activation mail has been sent": "Twoja wiadomość z aktywacją została wysłana",
	"Complete your profile in a minute and start contacting people": "Uzupełnij swój profil w ciągu minuty i zacznij kontaktować się z innymi osobami",
	"Complete your profile": "Uzupełnij swój profil",
	"Create Profile": "Utwórz profil",
	"Your description": "Twój opis",
	"Confirm your Email": "Potwierdź swój e-mail",
	"We have sent you an email.": "Wysłaliśmy Ci wiadomość e-mail.",
	"Check your email to complete your registration": "Sprawdź pocztę, aby dokończyć rejestrację",
	"and be able to speak with other users.": "i móc rozmawiać z innymi użytkownikami.",
	"If you can not find it look it up in the spam or": "Jeśli nie możesz znaleźć wiadomości od nas, poszukaj jej w spamie lub",
	"If you have not received it select one of the following options": "Jeśli go nie otrzymałeś, wybierz jedną z poniższych opcji",
	"junk folder": "folder śmieci",
	"Change Email": "Zmień adres e-mail",
	"Resend Email": "Wyślij ponownie e-mail",
	"Resend": "Reenvíar",
	"New Email": "Nowy e-mail",
	"Your activation key is invalid": "Twój kod aktywacyjny jest nieprawidłowy",
	"Please include an '@' in the email address. '{email}' doesn't have an '@'": "Wpisz '@' w adresie e-mail. {email} nie ma znaku '@'",

	//public pages
	"privacy": "prywatność",
	"terms-and-conditions": "warunki-i-zasady",
	"about-us": "o-nas",

	//forms
	"Name": "Imię",
	"Marital Status": "Stan cywilny",
	"Date of Birth": "Data urodzenia",
	"Country": "Kraj",
	"Location": "Lokalizacja",
	"Province": "Województwo",
	"City": "Miasto",
	"Save Changes": "Zapisz zmiany",
	"Select from list": "Wybierz z listy",
	"Day": "Dzień",
	"Month": "Miesięce",
	"month": "miesiąc",
	"Year": "Rok",
	"Prefer not to say": "Wolę zatrzymać dla siebie",
	"Only profiles with photo": "Tylko profile ze zdjęciem",
	"This field is required": "To pole jest wymagane",
	"Please update your profile": "Zaktualizuj swój profil",
	"Profile successfully updated": "Profil został pomyślnie zaktualizowany",
	"The fields marked in red are required. Please fill them to continue.": "Pola zaznaczone na czerwono są wymagane. Wypełnij je, aby kontynuować.",
	"Characters left": "Pozostało znaków",
	"Image attached": "Zdjęcie zostało załączone",
	"The message field is required.": "Pole wiadomości jest wymagane.",
	"WITH PICTURE": "ZE ZDJĘCIEM",
	"all age groups": "wszystkie grupy wiekowe",
	"EDIT DESCRIPTION": "EDYTUJ OPIS",
	"DESCRIPTION": "OPIS",
	"FEATURING": "CECHY",
	"Please Select": "Proszę wybrać",
	"Emails do not match": "E-maile nie zgadzają się",
	"Your request was succesfully sent!": "Twoja prośba została pomyślnie wysłana!",
	"Or send us a message directly": "Lub wyślij nam wiadomość bezpośrednio",

	//user pages
	"My Profile": "Mój profil",
	"Add Photos": "Dodaj zdjęcia",
	"Messages": "Wiadomości",
	"Favorites": "Ulubione",
	"Search": "Wyszukaj",
	"Settings": "Ustawienia",
	"Visits": "Wizyty",
	"Likes": "Polubienia",
	"Membership": "Członkostwo",
	"Vip Members": "Członkowie VIP",

	"Account activation successful": "Aktywacja konta powiodła się",
	"My chats": "Moje czaty",

	//user home
	"Quick Search": "Szybkie wyszukiwanie",
	"More Options": "Więcej opcji",
	"I am a": "Jestem",
	"Looking for": "Szukam",
	"Age": "Wiek",
	"Search for": "Wyszukaj",
	"All age groups": "Wszystkie grupy wiekowe",
	"Popular Members": "Popularni członkowie",
	"New Members": "Nowi członkowie",
	"Find a Match": "Dopasuj",
	"Recent Activity": "Ostatnia aktywność",
	"Add Photo": "Dodaj zdjęcie",
	"Back": "Wróć",
	"Become Elite": "Zostań Elite",
	"Upgrade": "Rozszerz",

	"likes": "Polubienia",
	"EDIT MY PROFILE": "EDYTUJ MÓJ PROFIL",
	"CONFIGURATION": "KONFIGURACJA",
	"ONLINE SUPPORT": "WSPARCIE ONLINE",

	//user message
	"Trash": "Kosz",
	"Delete Selected": "Usuń zaznaczone",
	"No Messages Available": "Brak dostępnych wiadomości",
	"No Messages selected": "Nie wybrano wiadomości",
	"Back to inbox": "Wróć do skrzynki odbiorczej",
	"Back to messages": "Wróć do wiadomości",
	"Select all": "Zaznacz wszystko",
	"Deselect all": "Odznacz wszystko",
	"Next Page": "Następna strona",
	"Prev Page": "Poprzednia strona",
	"Type your message here": "Tutaj wpisz swoją wiadomość",
	"Send message": "Wyślij wiadomość",
	"Send Icebreaker": "Wyślij lodołamacza",
	"VIEW PROFILE": "ZOBACZ PROFIL",
	"DELETE": "USUŃ",
	"Chat with": "Czat z",
	"Chat": "Czat",

	//user favorites
	"My Favorites": "Moje ulubione",
	"No likes": "Brak ulubionych",
	"Favorite added successfully": "Dodano do ulubionych",
	"Favorite deleted successfully": "Usunięto z ulubionych",
	"Visit Profile": "Odwiedź profil",

	//user visits
	"My Profile Visitors": "Odwiedzający mój profil",
	"No Visitors": "Brak odwiedzających",

	//user photos
	"My pictures": "Moje zdjęcia",
	"See my public profile": "Zobacz mój profil publiczny",
	"Profile photo": "Zdjęcie profilowe",
	"Profile Picture": "Zdjęcie profilowe",
	"2MB or lower.": "2 MB lub mniej.",
	"2MB or lower": "2 MB lub mniej",
	"Nudes not allowed in this category.": "W tej kategorii niedozwolone są zdjęcia w negliżu.",
	"PHOTO ALBUM": "FOTO ALBUM",
	"Allowed images 2 megabytes or less": "Dozwolone są zdjęcia o rozmiarze 2 megabajtow lub mniej",
	"Add picture": "Dodaj zdjęcie",
	"Main reasons for rejection": "Główne powody odmowy",
	"Are you sure?": "Jesteś pewny?",
	"Are you sure you want to delete this image?": "Czy na pewno chcesz usunąć to zdjęcie?",
	"Close": "Zamknij",
	"Confirm": "Potwierdź",
	"Attaching image": "Załączanie zdjęcia",
	"The message must be at least 2 characters.": "Wiadomość musi mieć co najmniej 2 znaki.",
	"Deleting...": "Usuwam...",
	"Pictures of underage people": "Zdjęcia osób niepełnoletnich",
	"Personal data is visible": "Dane personalne są widoczne",
	"Fake or stolen pictures": "Fałszywe lub skradzione zdjęcia",
	"Different gender to profile": "Inna płeć niż podana w profilu",
	"Group pictures": "Zdjęcia grupowe",
	"Weapons, drugs, violence": "Broń, narkotyki, przemoc",
	"No people visible": "Brak widocznych osób",
	"Info": "Informacje",
	"We are reviewing your images. When they pass the verification process they will be shown to the other users.": "Sprawdzamy Twoje zdjęcia. Gdy przejdą proces weryfikacji, będą widoczne dla innych użytkowników.",

	//user profiles
	"Profile": "Profil",
	"yrs": "lat",
	"years": "lat",
	"yr from": "lat, ",
	"Physique": "Budowa ciała",
	"Photos": "Zdjęcia",
	"No Photos": "Brak zdjęć",
	"About Me": "O mnie",
	"Characteristics": "Charakterystyka",
	"Prefers": "Preferuje",
	"Relationship": "Stan cywilny",
	"Shape": "Kształt",

	//user edit profile
	"DESCRIBE YOURSELF": "OPISZ SIEBIE",
	"GENERAL": "OGÓLNE",
	"(Will be verified)": "(Opis będzie zweryfikowany)",
	"Sex": "Płeć",
	"Seeking": "Szukam",
	"Finish your profile": "Dokończ swój profil",
	"Welcome! As your profile is new, we will give maximum visibility once you complete it.": "Witamy! Ponieważ Twój profil jest nowy, po wypełnieniu go otrzymasz maksymalną widoczność.",

	//search profile
	"Advanced Search": "Zaawansowane Wyszukiwanie",
	"OPEN TO": "SZUKAM",
	"PREFERENCES": "PREFERENCJE",
	"CHARACTER": "CHARAKTER",
	"Search Result": "Wynik wyszukiwania",
	"No Member found": "Nie znaleziono członka",
	"Change Search": "Zmień wyszukiwanie",
	"Hair Color": "Kolor włosów",
	"Eye Color": "Kolor oczu",
	"Body Type": "Budowa ciała",
	"Height": "Wzrost",
	"Weight": "Waga",
	"Smoker": "Palenie",
	"Ethnicity": "Pochodzenie etniczne",
	"From": "Od",
	"To": "Do",

	//settings
	"Change E-mail address": "Zmień adres e-mail",
	"Your email address has been successfully updated": "Twój adres e-mail został pomyślnie zaktualizowany",
	"Password": "Hasło",
	"Account Status": "Status Konta",
	"Cancel": "Anuluj",
	"Delete Account": "Usuń konto",
	"Notifications": "Powiadomienia",
	"Receive all notifications individually": "Otrzymuj wszystkie powiadomienia indywidualnie",
	"Receive one daily email will all my notifications": "Otrzymuj codziennie jeden e-mail zawierający wszystkie twoje powiadomienia",
	"Receive a weekly email with all my notifications": "Otrzymuj co tydzień jeden e-mail zawierający wszystkie twoje powiadomienia",
	"I don\'t want to receive any notifications": "Nie chcę otrzymywać żadnych powiadomień",
	"(we will send you a verification email)": "(wyślemy Ci e-mail weryfikacyjny)",
	"Current Password": "Aktualne hasło",
	"New Password": "Nowe hasło",
	"Enter valid email": "Wprowadź poprawny e-mail",
	"Repeat New Password": "Powtórz nowe hasło",
	"Save Settings": "Zapisz ustawienia",
	"Passwords should match": "Hasła muszą się zgadzać",
	"Password length should be minimum 5 characters": "Długość hasła powinna wynosić minimum 5 znaków",
	"Attention: Your new email address must be verified by clicking on the link that we just sent you.": "Uwaga: Twój nowy adres e-mail należy zweryfikować, klikając w link, który właśnie wysłaliśmy.",
	"I want to receive notifications by email": "Chcę otrzymywać powiadomienia e-mailem",
	"Status": "Status",
	"Renewal": "Odnowienie",
	"Auto renewal Canceled": "Anulowano automatyczne odnawianie",
	"Delete profile": "Usuń profil",
	"Your account has been deleted. You will be redirected.": "Twoje konto zostało usunięte. Zostaniesz przekierowany.",
	"Successfully updated": "Zaktualizowano pomyślnie",
	"Current account status": "Aktualny status konta",
	"Are you sure you want to cancel your subscription?": "Czy na pewno chcesz anulować swoją subskrypcję?",
	"Your subscription has been successfully canceled and will not be auto-renewed": "Twoja subskrypcja została pomyślnie anulowana i nie zostanie automatycznie odnowiona",
	"Due to technical reasons, cancellation of the renewal of the trial must be made after 8 hours of the acquisition. For any other subscription, the cancellation must be done at least 8 hours after acquisition and before 48 hours of its expiration.": "Z przyczyn technicznych, Anulowanie przedłużenia okresu próbnego należy dokonać po 8 godzinach od nabycia. Dla każdej innej subskrypcji, anulowanie musi nastąpić co najmniej 8 godzin po nabyciu i 48 godzin przed datą wygaśnięcia.",
	"Profile deleted. Redirecting...": "Profil usunięty. Przekierowuję ...",
	"*Active subscriptions deleted this way will not be reimbursed": "* Aktywne subskrypcje anulowane w ten sposób, nie podlegają zwrotowi.",
	"Are you sure you want to delete * your account? Your information will be permanently erased.": "Czy na pewno chcesz usunąć swoje konto? Twoje informacje zostaną trwale usunięte.",
	"Settings successfully updated": "Ustawienia zostały pomyślnie zaktualizowane",
	"Disable Notification": "Wyłącz powiadomienia",
	"The notifications have been successfully disabled.": "Powiadomienia zostały pomyślnie wyłączone.",
	"Canceled Auto-Renewal": "Anulowano automatyczne odnawianie",

	//subscription
	"Only Vip members can contact more than 5 different users per day.": "Tylko członkowie VIP mogą kontaktować się z więcej niż 5 różnymi użytkownikami dziennie.",
	"Upgrade your status": "Zmień swój status",
	"Enjoy our exclusive benefits and make new contacts": "Skorzystaj z naszych wyjątkowych przywilejów i nawiązuj nowe kontakty.",
	"Users Online": "Użytkownicy online",
	"Discreet Billing. {site_name} will not appear in the invoice": "Dyskretne rozliczenia. Witryna {site_name} nie pojawi się na fakturze",
	"Month ::: Months": "Miesiące ::: Miesiące ::: Miesięcy",
	"3 days trial": "3 dniowy okres próbny",
	"Try 3 days": "Wypróbuj przez 3 dni",
	"MEMBERSHIP": "CZŁONKOSTWO",
	"Select": "Wybierz",
	"Send messages for 3 consecutive days": "Wysyłaj wiadomości przez 3 kolejne dni",
	"Only one trial subscription per user": "Tylko jedna subskrypcja próbna na użytkownika",
	"In order to offer better service without interruptions, all plans will be auto renewed at the cost of one of the following subscriptions based on the pack chosen above.": "Aby zapewnić lepszą obsługę bez przerywania, wszystkie plany będą automatycznie odnawiane za cenę jednej z następujących subskrypcji w oparciu o wybrany powyżej pakiet.",
	"for": "dla",
	"For full access to all details of events, including how to cancel it,": "Aby uzyskać pełny dostęp do wszystkich szczegółów zdarzeń, w tym sposobu anulowania,",
	"click here": "kliknij tutaj",
	"for terms and conditions,": "warunki i zasady,",
	"All profiles are reviewed and moderated by our team.": "Wszystkie profile są sprawdzane i moderowane przez nasz zespół.",
	"CURRENT PLAN": "AKTUALNY PLAN",
	"Basic (no trial bonus) You do not have any active pack at this moment and you are a BASIC user. To increase your chances of connecting with and contacting other users, you will need to upgrade to Elite or VIP status.": "Basic (bez premii próbnej). W tej chwili nie masz żadnego aktywnego pakietu i jesteś użytkownikiem BASIC. Aby zwiększyć swoje szanse na kontaktowanie się z innymi użytkownikami, będziesz potrzebował zmienić swój status na Elite lub VIP.",
	"MY BENEFITS": "MOJE BENEFITY",
	"{discount} cheaper": "{discount} taniej",
	"save": "Zaoszczędź",
	"Create my profile": "Utwórz profil",
	"Read messages": "Przeczytaj wiadomości",
	"Add to Favorites": "Dodaj do ulubionych",
	"Make yourself visible to other users by adding them to favorites": "Pokaż się innym użytkownikom, dodając ich do ulubionych",
	"Photo Album": "Foto Album",
	"No fake pictures allowed": "Fałszywe zdjęcia są niedozwolone",
	"Album pictures": "Zdjęcia z albumu",
	"Show yourself and increase your possibilities": "Pokaż się i zwiększ swoje możliwości",
	"Detailed profile search": "Szczegółowe wyszukiwanie profilu",
	"Find exactly what you are looking for": "Znajdź dokładnie to, czego szukasz",
	"See other user's pics": "Zobacz zdjęcia innych użytkowników",
	"Choose your favorite": "Wybierz ulubione",
	"Reply to and send messages": "Odpowiadaj i wysyłaj wiadomości",
	"Make direct contact with up to five users per day": "Nawiąż bezpośredni kontakt nawet z 5 użytkownikami dziennie",
	"Be shown first in searches": "Pokaż się jako pierwszy w wyszukiwaniach",
	"Your profile will be featured before the rest of the users": "Twój profil będzie wyświetlany przed pozostałymi użytkownikami",
	"Make direct contact with unlimited users": "Nawiąż bezpośredni kontakt z nieograniczoną liczbą użytkowników",
	"Choose payment method": "Wybierz metodę płatności",
	"LESS THAN": "MNIEJ NIŻ",
	"PER DAY": "ZA DZIEŃ",
	"DISCRETION": "DYSKRECJA",
	"Our website name will not be disclosed": "Nazwa naszej strony internetowej nie zostanie ujawniona",
	"Our name will not be disclosed": "Nasza nazwa strony nie pojawi się na fakturze",
	"100% Anonymous.": "100% Anonimowo.",
	"Choose your subscription": "Wybierz swoją subskrypcję",
	"Recommended": "Polecana",
	"Most Popular": "Najbardziej popularna",
	"Secure payments with": "Bezpieczne płatności za pomocą",
	"Your message has NOT been sent. You need to upgrade your status in order to send messages.": "Twoja wiadomość NIE została wysłana. Aby wysyłać wiadomości, musisz zmienić swój status.",
	"Your purchase has been successful": "Twój zakup się powiódł",
	"All subscriptions automatically renew to ensure optimum service. If you want to change the settings you can do it": "Wszystkie subskrypcje odnawiają się automatycznie, aby zapewnić optymalną obsługę. Jeśli chcesz to zmienić, możesz to zrobić",
	"here.": "tutaj.",
	"after_here": "",

	//ad
	"Become a": "Zostań",
	"PREMIUM MEMBER": "CZŁONKIEM PREMIUM",
	"And contact": "I skontaktuj się z",
	"more people": "większą ilością osób",

	"upgrade": "rozszerz",
	"Boost": "Wzmocnij",
	"boost": "Dodaj",
	"your profile": "swój profil",
	"Start chatting and meet more people": "Zacznij rozmawiać i poznaj więcej ludzi",
	"And get": "I otrzymaj",
	"more views": "więcej wyświetleń",

	"Now +3 extra Credits with each purchase": "Teraz +3 dodatkowe kredyty przy każdym zakupie",
	"Best Seller": "Bestseller",
	"Select your Package": "Wybierz swój pakiet",
	"Less than {amount} per credit": "Mniej niż {amount} za kredyt",
	"Less than": "Mniej niż",
	"per credit": "za kredyt",
	"Free credits": "Darmowe kredyty",
	"per purchase": "za zakup",
	"Premium Status doesn’t allow free messages.": "Status Premium nie zezwala na darmowe wiadomości.",
	"Chat now": "Porozmawiaj teraz",
	"Get Credits": "Zdobądź kredyty",
	"Credit": "Kredyt",
	"Credits": "Kredyty",
	"My credits": "Moje kredyty",
	"{count} Credits left": "{count} Pozostało kredytów",
	"Your message has not been sent, you need credits to be able to send messages.": "Twoja wiadomość nie została wysłana, potrzebujesz kredytów, aby móc wysyłać wiadomości..",
	"Icebreaker": "Lodołamacz",
	"Icebreaker today": "Lodołamacz na dziś",
	"The cost for sending an Icebreaker is 2 credits. The Icebreaker can only be used to start a conversation or in response to another icebreaker.": "Koszt wysłania lodołamacza to 2 kredyty. Lodołamaczy można używać tylko do rozpoczęcia rozmowy lub w odpowiedzi na innego lodołamacza.",
	"With the acquisition of each package, users make a purchase of a pack of credits that can be used to send messages. The ratio of credits per message is ten to one, that means, the user needs to have at least ten credits in his account to send a message. The cost for sending an Icebreaker is 2 credits.": "Wraz z nabyciem każdego pakietu, użytkownicy kupują pakiet kredytów, które można wykorzystać do wysyłania wiadomości. Stosunek kredytów na wiadomość wynosi dziesięć do jednego, to znaczy, że użytkownik musi mieć co najmniej dziesięć kredytów na swoim koncie, aby wysłać wiadomość. Koszt wysłania lodołamacza to 2 kredyty.",

	"Trial pack": "Pakiet próbny",
	"Only one trial pack per user allowed": "Dozwolony jest tylko jeden pakiet próbny na użytkownika",
	"Used": "Wykorzystany",

	"Exclusive Promotion": "Ekskluzywna promocja",
	"Account Deleted": "Konto usunięte",
	"Log out Successful": "Wylogowanie powiodło się",

	"Do you like FREE LIVE SEX?": "Lubisz BEZPŁATNY SEKS NA ŻYWO?",
	"Enter this code to enjoy a 10% discount:": "Skorzystaj z 10% zniżki, korzystając z tego kodu:",
	"Discount redeemable for your first online purchase. Expires in 2 hours.": "Ten kod jest przeznaczony na pierwszy zakup online i jest ważny tylko przez następne 6 godzin.",

	"This account has been deleted.": "To konto zostało usunięte.",
	"Our algorithm has found a better option in line with your taste.": "Nasz algorytm znalazł lepszą opcję zgodną z Twoimi upodobaniami.",
	"You will be redirected in a few seconds.": "Zostaniesz przekierowany za kilka sekund.",

	"Has added you to favorites": "Dodał Cię do ulubionych",
	"Is looking at your profile": "Patrzy na Twój profil",
	"See profile": "Zobacz profil",
	"See message": "Zobacz wiadomość",

	"I think I am in love": "Myślę, że mogę się zakochać!",
	"Hey! I'm winking at you": "Hej! Mrugam do Ciebie",
	"Tell me more about you. Fancy a chat?": "Powiedz mi coś więcej o sobie. Masz ochotę porozmawiać?",
	"Some flowers for you!": "Kwiaty dla Ciebie",
	"Sending you a kiss": "Przesyłam Ci buziaka",
	"Surprise, it\'s me!": "Niespodzianka, To ja!",
	"Hi, how are you?": "Cześć, Jak się masz?",
	"Let's get naughty": "Bądźmy niegrzeczni",

	"Get 5 times the regular response rate. Premium members benefits:": "Uzyskaj pięciokrotnie wyższy wskaźnik odpowiedzi. Korzyści dla członków premium:",
	"Appear high up on searches": "Wyświetlaj się wysoko w wyszukiwaniach",
	"Distinctive badge that increases your visibility": "Specjalna odznaka, która zwiększa Twoją widoczność",
	"5 Free Icebreakers a day": "5 darmowych lodołamaczy dziennie",
	"In addition to this, a voucher for a 15% Webcam discount": "Na dodatek do tego, kupon rabatowy w wysokości 15% zniżki na kamerę internetową",

	"The Icebreaker can only be used to start a conversation or in response to another icebreaker.": "Lodołamacza można używać tylko do rozpoczęcia rozmowy lub w odpowiedzi na innego lodołamacza.",

	"Succesful purchase": "Pomyślny zakup",
	"Your purchase has been successful.": "Twój zakup się powiódł.",
	"It can take a few minutes to update.": "Aktualizacja może zająć kilka minut.",
	"You will be redirected.": "Zostaniesz przekierowany.",

	"Changes can take some minutes to update.": "Aktualizacja zmian może zająć kilka minut.",

	"Yearly Package": "Pakiet roczny",
	"Package": "Pakiet",
	"Buy Now": "Kup Teraz",
	"The Elite status allows you to contact up to 5 different users every day by sending them unlimited messages.": "Status Elite pozwala codziennie kontaktować się z maksymalnie 5 różnymi użytkownikami, wysyłając im nieograniczoną liczbę wiadomości.",

	"Exclusive offer": "Ekskluzywna oferta",
	"Welcome": "Witaj",
	"Mary from {domain_name} support": "Marta ze wsparcia z {domain_name}",
	"Welcome to {domain_name}. We hope you find what you are looking for.": "Witamy w {domain_name}. Mamy nadzieję, że znajdziesz to, czego szukasz.",
	"Here are some Pro Tips to be more successful and meet people:": "Oto kilka profesjonalnych wskazówek, aby odnieść większy sukces i spotkać więcej osób:",
	"Upload a profile picture to get more connections.": "Prześlij zdjęcie profilowe, aby nawiązać więcej znajomości.",
	"Send an icebreaker to get a conversation easily started.": "Wyślij lodołamacza, aby łatwiej rozpocząć rozmowę.",
	"If you like someone don’t forget to add them to favorites so they will know.": "Jeśli znajdziesz kogoś, kogo lubisz, nie zapomnij dodać go do ulubionych, aby mógł się o tym dowiedzieć.",
	"And of course, if you like somebody send a message and ask for a date!": "I oczywiście, jeśli kogoś polubisz wyślij wiadomość i zapytaj o randkę!",
	"If you need any extra help, please contact our ": "Jeśli potrzebujesz dodatkowej pomocy, prosimy o kontakt z naszym ",
	"support": "zespołem wsparcia",
	". We will be happy to help you.": ". Chętnie Ci pomożemy.",
	"Try your luck": "Spróbuj szczęścia",
	"64% of our users have a date in the first 2 weeks.": "64% naszych użytkowników ma randkę w ciągu pierwszych 2 tygodni.",
	"Good Luck!.": "Powodzenia!.",
	"*This message will be deleted upon closing.": "* Ta wiadomość zostanie usunięta po zamknięciu.",

	"Private picture": "Zdjęcie prywatne",
	"Private pictures will appear blurred out. Only users that have already paid will be able to see them.": "Prywatne zdjęcia będą zamazane. Tylko użytkownicy, którzy już zapłacili, będą mogli je zobaczyć.",
	"Name change": "Zmiana nazwy",
	"If you want to change your name or nickname, please contact our support team and we will do it within 24h.": "Jeśli chcesz zmienić swoje imię lub nickname, skontaktuj się z naszym zespołem obsługi klienta, a zrobimy to w ciągu 24h.",
	"See all images": "Zobacz wszystkie zdjęcia",
	"Unlock all private photos forever for just 20 credits": "Odblokuj wszystkie prywatne zdjęcia na zawsze za jedyne 20 kredytów",
	"Unlock forever all private pictures": "Odblokuj na zawsze wszystkie prywatne zdjęcia",

	"This email address is not registered, please sign up to log in.": "Ten adres e-mail nie jest zarejestrowany, zarejestruj się, aby się zalogować.",
	"Congratulations, your account is now active.": "Gratulacje, twoje konto jest teraz aktywne.",
	"Complete one last step and have fun!": "Wykonaj ostatni krok i baw się dobrze!",
	"OR": "LUB",

	"We sent you an email with a link to activate your account valid for the next 12 hours to:": "Wysłaliśmy Ci wiadomość e-mail z linkiem do aktywacji konta, link jest ważny przez następne 12 godzin:",
	"If you haven\'t received it in your inbox or ": "Jeśli nie otrzymałeś go na swoją skrzynkę odbiorczą lub ",
	"or you want to modify the email address, click on the button below.": "chcesz zmienić adres e-mail, kliknij w przycisk poniżej.",
	"We sent you an email to verify your account, the email will be valid for the next 12 hours:": "Wysłaliśmy Ci wiadomość e-mail w celu zweryfikowania konta, e-mail będzie ważny przez następnych 12 godzin:",
	"If you can\'t find it in your inbox or spam, you can edit or resend it": "Jeśli nie możesz go znaleźć w swojej skrzynce odbiorczej lub w spamie, możesz go edytować lub ponownie wysłać",
	"Resend or change email": "Wyślij ponownie lub zmień e-mail",
	"Please use the same email you registered.": "Użyj tego samego adresu e-mail, pod którym się zarejestrowałeś.",
	"Connect with Google": "Połącz z Google",
	"Connect with Yahoo": "Zarejestruj się z Yahoo",
	"Connect with Microsoft": "Połącz z Hotmail",
	"Or turn back": "Wróć",
	"Complete your registration": "Dokończ rejestrację",

	"It will not be shared": "Nie zostaną udostępnione",
	"It will never be shared. 100% Privacy": "Nie zostaną udostępnione, 100% prywatności",

	"Unlock all private pictures forever.": "Odblokuj prywatne zdjęcia na zawsze.",
	"Unlock": "Odblokowanie",

	"You will be redirected in a second": "Za kilka sekund zostaniesz przekierowany",

	"Card payment": "Płatność kartą",
	"Bank transfer": "Przelew bankowy",
	"Prepaid card": "Karta przedpłacona",
	"SofortBanking": "Sofort",

	"Error": "Błąd",
	"page not found": "Strona nie została znaleziona",
	"Back to homepage": "Wróć",

	"per month": "miesięcznie",
	"week": "tydzień",
	"{count} week": "{count} tydzień",

	"Your profile has been blocked": "Twój profil został zablokowany",
	"In order to offer the best possible user experience, we block all profiles that haven\'t sent a message within 21 days of registration.": "Aby zapewnić jak najlepsze wrażenia użytkownikom, blokujemy wszystkie profile, które nie wysłały wiadomości w ciągu 21 dni od rejestracji.",
	"Please acquire a credit package to unlock your profile and restore all its features.": "Aby odblokować swój profil i przywrócić wszystkie jego możliwości należy nabyć pakiet kredytów.",
	"Restore access to:": "Przywróć dostęp do:",
	"All messages": "Wszystkie wiadomości",
	"Users search": "Wyszukaj użytkowników",
	"Your contacts": "Twoje kontakty",
	"All favourites": "Wszystkie ulubione",
	"All pictures": "Wszystkie zdjęcia",
	"Send Icebreakers": "Wyślij lodołamacza",
	"Unblock Profile": "Odblokuj profil",

	"This website uses its own and third-party cookies and identifiers to improve accessibility and analyse our traffic. If you continue browsing, we consider that you accept its use. For more information visit our": "Ta strona korzysta z wewnętrzynych i zewnętrznych plików cookie i identyfikatorów w celu poprawy dostępności i analizy ruchu. Jeśli będziesz kontynuować przeglądanie, uważamy, że akceptujesz ich użycie. Aby uzyskać więcej informacji, odwiedź",
	"Cookies Policy": "Polityka plików cookies",

	"DATE GUARANTEE": "GWARANCJA RANDKI",
	"Take advantage of our Date guarantee": "Skorzystaj z naszej gwarancji randki",
	"No date? Money Back!": "Brak randki? Zwrot pieniędzy!",
	"No risk": "Bez ryzyka",
	"Guarantee of success": "Gwarancja sukcesu",
	"Terms and Conditions": "Warunki i Zasady",

	"Boost your visibility": "Zwiększ swoją widoczność",
	"SAVE": "ZAOSZCZĘDŹ",
	"Add 3 months premium": "Dodaj 3 miesiące premium",
	"Add {months} months premium": "Dodaj {months} miesiące premium",
	"We guarantee you a real date.": "Gwarantujemy prawdziwą randkę.",
	"YES, I WANT A DATE": "TAK, CHCĘ RANDKĘ",

	"Terms and conditions of the date guarantee. In order to fulfill all refund conditions, you must have a fully completed profile for a period of at least six months and your personal description in your profile. Your profile must contain at least one approved picture throughout this period. Furthermore, each week, you must contact at least seven different members located close to you (radius of 100km) within your age range (-5/+5 years). If you fulfill all the conditions listed above and you still were not able to find a date, we will full refund all the payments you made during this time. This guarantee is only valid for the first 12 months following your registration. Good luck!": "Warunki i zasady gwarancji randki. Aby spełnić wszystkie warunki zwrotu pieniędzy, musisz mieć całkowicie wypełniony profil przez okres co najmniej sześciu miesięcy oraz swój personalny opis w swoim profilu. Twój profil musi zawierać co najmniej jedno zatwierdzone zdjęcie przez cały ten okres. Ponadto każdego tygodnia musisz skontaktować się z co najmniej siedmioma różnymi członkami znajdującymi się blisko Ciebie (w promieniu 100 km) w Twoim przedziale wiekowym tj. (-5 / + 5 lat). Jeśli spełniasz wszystkie powyższe warunki i nadal nie możesz znaleźć randki, zwrócimy w całości wszystkie płatności dokonane w tym czasie. Ta gwarancja jest ważna tylko przez pierwszych 12 miesięcy po rejestracji. Powodzenia!",

	"You have {count} days premium left": "Pozostało Ci {count} dni premium",
	"Check our special offer before deleting your account!": "Sprawdź naszą ofertę specjalną przed usunięciem konta!",
	"Delete my account": "Usuń moje konto",
	"Free": "Gratis",

	"Premium, Elite and VIP packages are auto-renewable, which means they will auto-renew when they expire.": "Pakiety Premium, Elite i VIP są automatycznie odnawiane, co oznacza, że odnawiają się automatycznie po ich wygaśnięciu.",
	"and can confirm that I am 18 years or older.": "oraz potwierdzasz, że masz więcej niż 18 lat.",

	"COMPLETE YOUR PURCHASE": "DOKOŃCZ SWOJE ZAKUPY",
	"Payment details": "Szczegóły płatności",
	"Total": "Łącznie",
	"3-digit number on the back of your credit card": "3-cyfrowy numer na odwrocie Twojej karty kredytowej",
	"MM / YY": "MM / RR",
	"MM": "MM",
	"YY": "RR",
	"Name on card": "Imię i Nazwisko na karcie",
	"Charges will appear discreetly as tpmpsup.com": "Opłaty pojawią się dyskretnie jako tpmpsup.com",
	"Buy Now": "Kup Teraz",
	"Premium package 3 Months": "Pakiet premium 3 miesięcy",

	"This purchase was declined. Please check your details and try again.": "Twój zakup nie powiódł się. Sprawdź swoje dane i spróbuj ponownie.",
	"Your purchase was succesful.": "Twój zakup się powiódł.",

	"SPECIAL OFFER": "OFERTA SPECJALNA",
	"Unlock your account with our special offer": "Odblokuj swoje konto dzięki naszej specjalnej ofercie",
	"Take advantage of our special offer to unlock your profile and restore all its features.": "Skorzystaj z naszej specjalnej oferty, aby odblokować swój profil i przywrócić wszystkie jego funkcje.",

	"card_number_require": "Wymagany jest numer karty",
	"card_number_invalid": "Numer karty jest nieprawidłowy",
	"card_name_require": "Wymagane są imię i nazwisko posiadacza karty",
	"card_name_invalid": "Imię i nazwisko posiadacza karty są nieprawidłowe",
	"card_cvc_require": "Wymagany jest kod CVC",
	"card_cvc_invalid": "Kod CVC jest nieprawidłowy",
	"card_expiry_require": "Wymagana jest data ważności karty",
	"card_expiry_invalid": "Data ważności karty jest nieprawidłowa",

	"Private photo": "Prywatne zdjęcie",
	"Search for someone now": "Wyszukaj kogoś teraz",
	"Edit": "Edytuj",

	"Locked Album": "Album zablokowany",
	"Get credits to unlock all albums": "Zdobądź kredyty, aby odblokować wszystkie albumy",
	"picture ::: pictures": "zdjęcie ::: zdjęcia",
	"Open": "Otwórz",

	"Receive notifications": "Otrzymuj powiadomienia",
	"I want to be notified when I receive a message": "Chcę być powiadamiany o otrzymaniu wiadomości",
	"No": "Nie",
	"Yes": "Tak",

	"logout_confirm_title": "Wyloguj", 
	"Are you sure you want to log out?": "Czy na pewno chcesz się wylogować?",

	"Your payment has been cancelled": "Twoja płatność została anulowana",
	"Something went wrong, please try again in 10 minutes": "Coś poszło nie tak, spróbuj ponownie za 10 minut",

	"Please accept the terms and conditions to continue": "Proszę zaakceptować regulamin, aby kontynuować",
	"By checking this box and clicking continue I accept the": "Zaznaczając to pole i klikając kontynuuj, akceptuję",
	"rg_terms": "Regulamin",
	"rg_privacy": "Polityka prywatności",
	"rg_cookies": "Pliki cookie",
	"I am aware of the use of fictitious profiles on the website and I confirm that I am of legal age.": "jestem świadomy korzystania z fikcyjnych profili na stronie i potwierdzam, że jestem pełnoletni.",

	"Cookie": "Cookie",
	"Responsible Party": "Odpowiedzialna strona",
	"Category": "Kategorie",
	"Cookie Name": "Nazwa",
	"Purpose": "Cel",
	"Lifespan": "Żywotność",
	"Type of Cookie": "Rodzaj pliku cookie",
	"Strictly necessary": "Ściśle konieczne",
	"Functional": "Funkcjonalność",
	"Performance": "Wydajność",
	"Advertising": "Advertising",
	"cookie-policy": "polityka-cookie",
	"cookie policy": "polityką dotyczącą plików cookie",

	"We Care About Your Privacy": "Dbamy o Twoją prywatność",
	"This site uses its own, technical and third party cookies to improve navigation performance, analyzing visitors behaviour to help us and our marketing partners measure campaign results and personalized advertising. By clicking \"Accept All\" you are agreeing to all of these cookies.": "Ta strona używa własnych, technicznych i zewnętrznych plików cookie w celu poprawy wydajności nawigacji, analizy zachowań odwiedzających i pomocy nam i naszym partnerom marketingowym w mierzeniu wyników kampanii i spersonalizowana reklama. Klikając „Akceptuj wszystko”, zgadzasz się na wszystkie te pliki cookie.",
	"Accept All": "Akceptuj wszystko",
	"Cookies Settings": "Ustawienia plików cookie",
	"About your privacy": "O Twojej prywatności",
	"We use cookies for traffic balancing, session identification, analytics and marketing attribution. You can opt-in or opt-out of any or all of them from this panel.": "Używamy plików cookie w celu dostosowania ruchu, identyfikacji sesji, analizy i alokacji marketingowej. Możesz wyrazić zgodę lub zrezygnować z dowolnego lub wszystkich z nich w tym panelu.",
	"You will be able to change your set up at any time from our :cookies_policy page": "W każdej chwili będziesz mógł zmienić swoją konfigurację na naszej stronie z :cookies_policy",
	"Accept all": "Akceptuj wszystko",
	"Manage Consent Preferences": "Zarządzaj preferencjami zezwoleń",
	"Technical (Mandatory)": "Techniczne (ściśle konieczne)",
	"Performance (Monitor website performance)": "Wydajność (monitorowanie wydajności witryny)",
	"Functional (User experience improvement)": "Funkcjonalność (poprawa wrażeń użytkownika)",
	"Advertising (Marketing measurement)": "Reklama (pomiary marketingowe)",
    "Advertising (Personalization)": "Reklama (personalizacja)",
	"Save Selection": "Zapisz wybór",
	"Reject All": "Odrzuć wszystko",

	"We and our partners process data to provide:": "My i nasi partnerzy przetwarzamy dane w celu zapewnienia:",
	"Use precise geolocation data. Actively scan device characteristics for identification. Store and/or access information on a device. Personalised ads and content, ad and content measurement, audience insights and product development.": "Użyj dokładnych danych geolokalizacyjnych. Aktywnie skanuj charakterystykę urządzenia w celu identyfikacji. Przechowuj i/lub uzyskuj dostęp do informacji na urządzeniu. Spersonalizowane reklamy i treści, pomiar reklam i treści, statystyki odbiorców i rozwój produktów.",
	"List of partners": "Lista partnerów",
	"Cookie Policy": "Polityka Cookies",
	"These cookies are used in connection with the tracking of actions related to advertisements. For example, these cookies will remember that you have visited {name} and can be used to show you a personalized advertisement on another website. Please check the following links of the platforms where we promote ourselves, Google, Microsoft and Twitter. for more information.": "Te pliki cookies są używane w celu śledzenia działań związanych z reklamami. Na przykład te pliki cookies zapamiętują, że odwiedziłeś {name} i mogą być wykorzystywane do wyświetlania spersonalizowanej reklamy na innej stronie internetowej. Sprawdź poniższe linki do platform, na których się promujemy, Google, Microsoft i Twitter. po więcej informacji",
	"Delete cookies": "Cookies",
	"Save selection": "Zapisz wybór",
	"Reject all": "Odrzuć wszystko",

	"{name} hasn't replied to you yet. Send another message!": "{name} jeszcze Ci nie odpowiedziała. Wyślij następną wiadomość!",
	"Validate my Ice-breaker": "Potwierdź mój lodołamacz",

	"Required Data": "Wymagane dane",
	"Continue": "Kontynuuj",

	"Distance": "Odległość",
	"now": "teraz",

	"Starting  June 1, 2023 onwards, we will not process payments with PaySafeCard.": "Od dnia 01 czerwca 2023 r. nie będzie można dokonywać płatności kartą PaySafeCard.",

	"Dear User,": "Drogi Użytkowniku,",
	"Thanks for your interest in joining our website, but, in order to keep a gender balance close to 50/50 men and women, registration is not allowed for men, at the moment.": "Dziękujemy za zainteresowanie dołączeniem do naszej strony internetowej, ale w celu utrzymania równowagi płci blisko 50/50 mężczyzn i kobiet, w tej chwili rejestracja mężczyzn nie jest możliwa.",
	"If you still want a date, click on the button below and try one of the best websites online.": "Jeśli nadal chcesz umówić się na randkę, kliknij przycisk poniżej i wypróbuj jedną z najlepszych witryn internetowych.",
	"Try Now": "Spróbuj teraz",

	"Your account has been deleted.": "Twoje konto zostało usunięte.",
	"According to your choices, our algorithms have found a better option for you.": "Zgodnie z Twoimi wyborami, nasze algorytmy znalazły dla Ciebie lepszą opcję.",
	"See More": "Zobacz więcej",

	"10 credits for you!": "10 kredytów dla Ciebie!",
	"Get 3 free Icebreakers": "Zdobądź 3 darmowe lodołamacze",
	"Free Icebreakers": "Darmowe lodołamacze",
	"Congratulations, you have received 3 Icebreakers!": "Gratulacje, zdobyłeś 3 Lodołamacze!",

	"Apple pay only works with the Safari browser. Please open your Safari browser and login to your account in order to proceed with this payment.": "Apple Pay działa tylko z przeglądarką Safari. Aby kontynuować płatność, otwórz przeglądarkę Safari i zaloguj się na swoje konto.",

	"No nudity": "Nagość zabroniona",
	"Nudity allowed": "Nagość dozwolona",

	"Edit profile": "Edytuj profil",
	"Online": "Online",
	"Step": "Krok",
	"Completed": "Zakończone",
	"Help": "Pomoc",
	"or Drag and drop": "lub przeciągnij i puść",

	"You will be able to send messages in": "Będziesz mógł wysyłać wiadomości za",
	"Send": "Wyślij",
	"Success": "Sukces",
	"Start a conversation with {name}": "Rozpocznij rozmowę z {name}",
	"Failed": "Nie udało się",
	"Congratulations, your account has been successfully created.": "Gratulacje, Twoje konto zostało pomyślnie utworzone.",
	"Contact our support": "Skontaktuj się z naszym wsparciem",
	
	"About": "O",
	"Scale": "Dostosuj",
	"Rotate": "Obróć",

	"No favorites added": "Nie dodano ulubionych",

	"Didn\'t find what you were looking for?": "Nie możesz znaleźć tego, czego szukałeś?",
	"Please send us an email and we will get back to you.": "Wyślij nam e-mail, a my odpowiemy Ci tak szybko, jak to możliwe.",
	"Or contact us using the form": "Lub skontaktuj się z nami za pomocą formularza."
	
}
